const { CognitoUserPool } = require('amazon-cognito-identity-js');
const variables = require('./variable');

const poolData = {
  UserPoolId: variables.userPoolId,
  ClientId: variables.clientId,
};

const UserPoll = new CognitoUserPool(poolData);

export default UserPoll;
