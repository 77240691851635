/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import { Spinner } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FilePond } from 'react-filepond';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import * as variables from '../../config/variable';
import { apiSlice } from '../../features/api/apiSlice';
import { useGetAllAssetsQuery } from '../../features/assets/assetsAPI';
import { removeEpisodeShow } from '../../features/assets/seriesSlice';
import ErrorAlert from '../Message/ErrorAlert';

const UpdateSeries = ({ handelCancel, setCloseDetails, isEpisodeShow }) => {
  const dispatch = useDispatch();
  const { seriesDetails } = useSelector((state) => state.series);
  const [isPosting, setIsPosting] = useState(false);
  const [seriesName, setSeriesName] = useState('');
  const [oldSeriesName, setOldSeriesName] = useState('');
  const [seriesDescription, setSeriesDescription] = useState('');
  const [seriesCover, setSeriesCover] = useState(null);
  const [seriesCoverImageInfo, setSeriesCoverImageInfo] = useState({
    name: '',
    type: '',
  });
  const { data: listData } = useGetAllAssetsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    pollingInterval: 1200000,
  });
  const [episodeList, setEpisodeList] = useState([]);
  useEffect(() => {
    if (listData && listData.data && listData.data.Items && seriesDetails.seriesName) {
      const filteredData = listData.data.Items.filter(
        (item) => item.seriesName === seriesDetails.seriesName
      );
      setEpisodeList(filteredData);
    }
  }, [listData, seriesDetails.seriesName]);
  const [msgText, setMsgText] = useState(null);
  const { accessToken } = useSelector((state) => state.authentication);
  const preSigndUrl = async () => {
    const url = `${variables.apiUrl}/admin/asset/upload/url`;
    const data = {
      seriesCover: { name: seriesCoverImageInfo.name, type: seriesCoverImageInfo.type },
    };
    const response = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  };
  const updateSeries = async (updateData) => {
    try {
      const saveData = {
        seriesName,
        seriesDescription,
        oldSeriesName,
      };
      if (updateData?.seriesCover) {
        await axios.put(updateData.seriesCoverUrl, seriesCover, {
          headers: {
            'Content-Type': seriesCoverImageInfo.type,
          },
        });
        saveData.seriesCover = updateData?.seriesCover;
      }
      const response = await axios.put(`${variables.apiUrl}/admin/series`, saveData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response;
    } catch (error) {
      setMsgText(error?.message);
      return null;
    }
  };
  const handleSeriesNameChange = (event) => {
    setSeriesName(event.target.value);
  };
  const handleSeriesDescriptionChange = (event) => {
    setSeriesDescription(event.target.value);
  };
  const seriesCoverOnRemove = () => {
    setSeriesCover(null);
  };
  const onChangeSeriesCoverFile = (files) => {
    if (files?.length > 0) {
      setSeriesCoverImageInfo({ name: files[0].file?.name, type: files[0].file?.type });
      setSeriesCover(files[0].file);
    }
  };
  useEffect(() => {
    setSeriesDescription(seriesDetails?.seriesDescription);
    setSeriesName(seriesDetails?.seriesName);
    setOldSeriesName(seriesDetails?.seriesName);
    // setSeriesCover(seriesDetails?.seriesCover);
  }, [seriesDetails]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsPosting(true);
    const updatedData = {};
    if (seriesCover) {
      const preSgindResponse = await preSigndUrl();
      const response = preSgindResponse?.data;
      if (response) {
        const s3Url = response?.seriesCoverUrl.split('/seriescovers')[0];
        updatedData.seriesCover = `${s3Url}/${response?.seriesCoverName}`;
        updatedData.seriesCoverUrl = response?.seriesCoverUrl;
      }
    }
    const response = await updateSeries(updatedData);
    if (response?.data) {
      toast.success('Series Updated successfully');
      dispatch(apiSlice.util.invalidateTags(['getAssetsList']));
      dispatch(apiSlice.util.invalidateTags(['getAllAssetsOrderedList']));
      setCloseDetails(true);
    }
    setIsPosting(false);
  };
  const columns = [
    {
      name: 'Ep No',
      selector: (row) => row.episodNo,
      sortable: true,
      width: '20%',
    },
    {
      name: 'Image',
      cell: (row) => <img src={row.cover} alt={row.title} style={{ width: '60px' }} />,
    },
    {
      name: 'Title',
      selector: (row) => row.title,
      width: '50%',
    },
  ];
  return (
    <div className="p-6 bg-gray-100">
      {isEpisodeShow ? (
        <div className="h-[88vh] overflow-y-scroll overflow-x-hidden">
          <div className="flex mb-3 items-center justify-between">
            <h4 className="text-2xl font-normal text-soc-light-on-surface-variant">
              Series Details
            </h4>
            <div className="flex">
              <button
                className="inline-flex items-center p-1 rounded-full hover:bg-[#49454f24] transition-all"
                type="button"
                onClick={() => dispatch(removeEpisodeShow())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.06 3.79008L20.41 5.14008C21.2 5.92008 21.2 7.19008 20.41 7.97008L7.18 21.2001H3V17.0201L13.4 6.61008L16.23 3.79008C17.01 3.01008 18.28 3.01008 19.06 3.79008ZM5 19.2001L6.41 19.2601L16.23 9.43008L14.82 8.02008L5 17.8401V19.2001Z"
                    fill="#3F4949"
                  />
                </svg>
                <span className="ml-2">Update Series</span>
              </button>
            </div>
            <button onClick={handelCancel} type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#3F4949"
                />
              </svg>
            </button>
          </div>
          <div>
            <div className="mb-4">
              <label htmlFor="series" className="soc-input-label focunLevel">
                <span className="focusSpan soc-input-label-span">Series Name</span>
                <input
                  disabled={isEpisodeShow}
                  id="seriesName"
                  type="text"
                  value={seriesName}
                  onChange={handleSeriesNameChange}
                  className="soc-input"
                />
              </label>
            </div>
            <div className="mb-4">
              <label htmlFor="seriesDescription" className="soc-input-label focunLevel">
                <span className="focusSpan soc-input-label-span">Series Description</span>
                <textarea
                  disabled={isEpisodeShow}
                  id="seriesDescription"
                  rows={2}
                  value={seriesDescription}
                  onChange={handleSeriesDescriptionChange}
                  className="soc-input"
                />
              </label>
            </div>
            <div>
              <p className="mb-3 text-[#47464F] font-semibold text-medium">Series Cover</p>
              <img
                className="h-60 w-60 m-auto rounded-2xl object-cover"
                src={seriesDetails.seriesCover}
                alt="imageCover"
                style={{
                  width: '70%',
                  height: '50%',
                }}
              />
            </div>
            <div>
              {episodeList.length > 0 && (
                <DataTable
                  title="Series Episode List"
                  columns={columns}
                  data={episodeList}
                  defaultSortFieldId={1}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="h-[88vh] overflow-y-scroll overflow-x-hidden">
          <div className="flex mb-3 items-center justify-between">
            <h4 className="text-2xl font-normal text-soc-light-on-surface-variant">
              Update Series
            </h4>
            <button onClick={handelCancel} type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#3F4949"
                />
              </svg>
            </button>
          </div>
          <div>
            <div>
              <div className="mb-4">
                <label htmlFor="series" className="soc-input-label focunLevel">
                  <span className="focusSpan soc-input-label-span">Series Name</span>
                  <input
                    disabled={isPosting}
                    id="seriesName"
                    type="text"
                    value={seriesName}
                    onChange={handleSeriesNameChange}
                    className="soc-input"
                  />
                </label>
              </div>
              <div className="mb-4">
                <label htmlFor="seriesDescription" className="soc-input-label focunLevel">
                  <span className="focusSpan soc-input-label-span">Series Description</span>
                  <textarea
                    disabled={isPosting}
                    id="seriesDescription"
                    rows={2}
                    value={seriesDescription}
                    onChange={handleSeriesDescriptionChange}
                    className="soc-input"
                  />
                </label>
              </div>
            </div>
            <div className="border-t mt-5">
              <div className="mb-4">
                <div className="flex justify-between">
                  <label
                    htmlFor="imageUpload"
                    className="block mb-1 font-normal text-gray-800 text-sm"
                  >
                    Upload Series Cover
                  </label>
                </div>

                <FilePond
                  disabled={isPosting}
                  className="newAssetPond"
                  maxFiles={1}
                  onremovefile={seriesCoverOnRemove}
                  onupdatefiles={onChangeSeriesCoverFile}
                  allowImagePreview
                  acceptedFileTypes={['image/png', 'image/jpeg']}
                />
              </div>
            </div>
            {!!msgText && (
              // <Alert
              //   className={`${
              //     isUploadSuccess ? 'bg-green-200' : 'bg-red-200'
              //   } mb-2 text-black font-bold`}
              // >
              //   {msgText}
              // </Alert>
              <ErrorAlert message={msgText} />
            )}
            <div className="flex">
              <button
                disabled={isPosting}
                type="submit"
                className="soc-button flex items-center justify-center px-4 w-full"
              >
                <p className="mr-2">Update Series </p>
                {isPosting && <Spinner className="w-4 h-4" />}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default UpdateSeries;
