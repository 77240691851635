import { apiSlice } from '../api/apiSlice';

const assetsAPI = apiSlice.injectEndpoints({
  tagTypes: ['getAssetsList', 'getAllAssetsOrderedList'],
  endpoints: (builder) => ({
    getAllAssets: builder.query({
      query: (queryParameters) => ({
        url: '/asset/list',
        method: 'GET',
        params: queryParameters,
      }),
      providesTags: ['getAssetsList'],
    }),
    updateStatusAssets: builder.mutation({
      query: ({ assetId, value }) => ({
        url: `/admin/asset/${assetId}`,
        method: 'PUT',
        body: { hidden: value },
      }),
      invalidatesTags: ['getAssetsList', 'getAllAssetsOrderedList'],
    }),
    deleteAsset: builder.mutation({
      query: (assetId) => ({
        url: `/admin/asset/${assetId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getAssetsList', 'getAllAssetsOrderedList'],
    }),
    getAllAssetsOrderedList: builder.query({
      query: (queryParameters) => ({
        url: `/asset/ordered/list`,
        method: 'GET',
        params: queryParameters,
      }),
      providesTags: ['getAllAssetsOrderedList'],
    }),
  }),
});

export const {
  useGetAllAssetsQuery,
  useUpdateStatusAssetsMutation,
  useDeleteAssetMutation,
  useGetAllAssetsOrderedListQuery,
  // useLazyGetAllAssetsOrderedListQuery,
  // useLazyGetAllAssetsQuery,
} = assetsAPI;
