import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateNotification from '../Components/Notification/CreateNotification';
import NotificationList from '../Components/Notification/NotificationList';
import { rmoveNotificationDetails } from '../features/notification/notificationSlice';

const Notification = () => {
  const { notificationList } = useSelector((state) => state.notification);
  const [newNotification, setNewNotification] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const dispatch = useDispatch();

  const handelClickNewNotiFication = () => {
    dispatch(rmoveNotificationDetails());
    setOpenDetails(false);
    setNewNotification(true);
  };
  useEffect(() => {
    if (Object.keys(notificationList).length > 0) {
      setOpenDetails(true);
      setNewNotification(true);
    }
  }, [notificationList]);

  const handelCancel = () => {
    setNewNotification(false);
  };
  return (
    <section className="container p-3 mx-auto">
      <div className="grid grid-cols- xl:grid-cols-3 gap-7">
        <div className="col-span-2 bg-[#F7FAF9] p-8 rounded-xl overflow-hidden relative">
          <div className="flex justify-between items-center pb-3">
            <h2 className="text-3xl font-normal text-soc-light-on-surface-variant">
              Notifications
            </h2>
            <button
              onClick={handelClickNewNotiFication}
              type="button"
              className="soc-button w-auto flex items-center justify-center px-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={18}
                height={19}
                viewBox="0 0 18 19"
                fill="none"
              >
                <path
                  d="M15 9.78271H9.75V15.0327H8.25V9.78271H3V8.28271H8.25V3.03271H9.75V8.28271H15V9.78271Z"
                  fill="white"
                />
              </svg>
              <span className="mx-2">Add new Notification</span>
            </button>
          </div>

          <NotificationList />
        </div>

        {newNotification && (
          <div className="col-span-2 xl:col-span-1">
            <div className="bg-gray-100 bg-opacity-75 rounded-lg overflow-hidden relative">
              <CreateNotification
                openDetails={openDetails}
                handelCancel={handelCancel}
                setNewNotification={setNewNotification}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Notification;
