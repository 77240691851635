/* eslint-disable no-unused-vars */
const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
  return `${formattedMinutes}:${formattedSeconds}`;
};

const getAssetDuration = (ref) => {
  return new Promise((resolve, reject) => {
    const { file } = ref.current.getFile();
    const mediaElement = document.createElement(file.type.startsWith('audio') ? 'audio' : 'video');
    mediaElement.src = URL.createObjectURL(file);
    mediaElement.addEventListener('loadedmetadata', () => {
      resolve(formatDuration(mediaElement.duration));
      URL.revokeObjectURL(mediaElement.src);
    });
  });
};

export default getAssetDuration;
