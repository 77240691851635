import React from 'react';
import LoginForm from '../Components/Login/LoginForm';
import Logo from '../resources/logo.svg';

const Login = () => {
  return (
    <section className="bg-cover h-screen bg-no-repeat flex bg-[url('/public/main-bg.svg')]">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-soc-light-surface-container-high rounded-[28px] shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-[24px]">
            <img src={Logo} className="w-[65px] h-[65px] m-auto leading-[32px]" alt="logo" />
            <h1 className="text-[24px] px-[24px] mt-4 mb-6 font-normal text-center leading-8">
              Sign in to Surfing on a Cloud
            </h1>
            <LoginForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
