/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import jwtDecode from 'jwt-decode';
import getRefreshToken from '../../uitls/getRefreshToken';
import { setRfreshToken } from '../authentication/AuthenticationSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_SOAC_API_URL,
  prepareHeaders: async (headers, { getState }) => {
    const token = getState()?.authentication?.accessToken;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    // check if token is expired
    const token = api.getState(0)?.authentication?.accessToken;
    const decod = jwtDecode(token);
    const isExpireToken = decod.exp * 1000 < new Date().getTime();

    if (isExpireToken && result?.error?.status) {
      const auth = api.getState()?.authentication;
      const newSession = await getRefreshToken(auth?.userId, auth?.refreshToken);
      const accessToken = newSession?.getAccessToken()?.getJwtToken();
      const refreshToken = newSession?.getRefreshToken()?.getToken();
      const idToken = newSession?.getIdToken()?.getJwtToken();

      api.dispatch(
        setRfreshToken({
          userId: auth?.userId,
          accessToken,
          idToken,
          refreshToken,
        })
      );
    }
    return result;
  },
  endpoints: () => ({}),
});
