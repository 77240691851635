/* eslint-disable */
import { Button, Dropdown } from "flowbite-react";

const MultiSelectDropdown = ({ options = [] , selectedItems, setSelectedItems, isPosting}) => {
  const handleSelect = (option) => {
    if (selectedItems.includes(option)) {
      setSelectedItems(selectedItems.filter(item => item !== option));
    } else {
      setSelectedItems([...selectedItems, option]);
    }
  };

  const handleRemove = (option) => {
    setSelectedItems(selectedItems.filter(item => item !== option));
  };

  const isSelected = (option) => selectedItems.includes(option);
  const getCategoryNameById = (categoryId = "") => {
    const data = options.find(category => category.id === categoryId);
    return data ? data.category : '';
  }
  return (
    <div  className="w-full tagsselect">
      <Dropdown label="Select Categories" dismissOnClick={false} inline disabled={isPosting}>
        {options.map((option) => (
          <Dropdown.Item key={option.category} onClick={() => handleSelect(option.id)}>
            <div className="flex items-center">
              <input
                type="checkbox"
                disabled={isPosting}
                checked={isSelected(option.id)}
                onChange={() => handleSelect(option.id)}
                className="mr-2"
              />
              {option.category}
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown>

      {selectedItems.length > 0 && (<div className="mt-4">
        <div className="flex flex-wrap gap-2 mt-2">
          {selectedItems.map((item) => (
            <div
              key={item}
              className="flex items-center bg-gray-200 text-black px-2 py-1 rounded"
            >
              <span className="shrink-0">{getCategoryNameById(item)}</span>
              <Button
                size="xs"
                color="failure"
                className="ml-2"
                disabled={isPosting}
                onClick={() => handleRemove(item)}
              >
                X
              </Button>
            </div>
          ))}
        </div>
      </div>)}
    </div>
  );
};

export default MultiSelectDropdown;
