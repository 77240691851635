import { apiSlice } from '../api/apiSlice';

const assetsAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotification: builder.query({
      query: () => ({
        url: '/admin/notifications',
        method: 'GET',
      }),
      providesTags: ['getAllNotification'],
    }),
    createNotification: builder.mutation({
      query: (data) => ({
        url: '/admin/notifications',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['getAllNotification'],
    }),
  }),
});

export const { useGetAllNotificationQuery, useCreateNotificationMutation } = assetsAPI;
