import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import createExpirationTransform from 'redux-persist-transform-expire';
import storage from 'redux-persist/lib/storage';
import { apiSlice } from '../features/api/apiSlice';
import assetsSlice from '../features/assets/assetsSlice';
import categoriesSlice from '../features/assets/categoriesSlice';
import seriesSlice from '../features/assets/seriesSlice';
import authReducer from '../features/authentication/AuthenticationSlice';
import notificationSLice from '../features/notification/notificationSlice';

const expireTransform = createExpirationTransform({ expireKey: 'expiresAt' });

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
  transforms: [expireTransform],
};

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  authentication: authReducer,
  assets: assetsSlice.reducer,
  notification: notificationSLice.reducer,
  series: seriesSlice.reducer,
  categories: categoriesSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [];
middlewares.push(apiSlice.middleware);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production',
});

const persistor = persistStore(store);

export { persistor, store };
// eslint-disable-next-line prettier/prettier

