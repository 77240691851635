import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationList: {},
};

const notificationSLice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationDetails: (state, action) => {
      state.notificationList = action.payload;
    },
    rmoveNotificationDetails: (state) => {
      state.notificationList = {};
    },
  },
});

export default notificationSLice;

export const { setNotificationDetails, rmoveNotificationDetails } = notificationSLice.actions;
