import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assetsDetails: {},
};

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setAssetDetails: (state, action) => {
      state.assetsDetails = action.payload;
    },
    rmoveAssetDetails: (state) => {
      state.assetsDetails = {};
    },
  },
});

export default assetsSlice;

export const { setAssetDetails, rmoveAssetDetails } = assetsSlice.actions;
