/* eslint-disable jsx-a11y/label-has-associated-control */
import { Spinner } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useCreateNotificationMutation } from '../../features/notification/notificationAPI';
import ErrorAlert from '../Message/ErrorAlert';

const CreateNotification = ({ handelCancel, openDetails, setNewNotification }) => {
  const { notificationList } = useSelector((state) => state.notification);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [topic, setTopic] = useState('release');

  const [createNotification, { isSuccess, isError, isLoading, error, reset }] =
    useCreateNotificationMutation();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (title && message && topic) {
      createNotification({
        title,
        message,
        topic,
      });
    }
  };

  useEffect(() => {
    if (openDetails) {
      setTitle(notificationList?.title || '');
      setMessage(notificationList?.message || '');
    } else {
      setTitle('');
      setMessage('');
    }
  }, [notificationList?.message, notificationList?.title, openDetails]);

  useEffect(() => {
    if (isSuccess) {
      setNewNotification(false);
      toast.success('Notification sent successfully!');
      setTitle('');
      setMessage('');
      setTimeout(() => {
        reset();
      }, 3000);
    }
  }, [isSuccess, reset, setNewNotification]);

  return (
    <div className="p-6 bg-gray-100">
      <form onSubmit={handleSubmit} className="h-[88vh] relative">
        <div className="flex mb-3 items-center justify-between">
          <h4 className="text-2xl font-normal text-soc-light-on-surface-variant">
            {!openDetails ? 'Send New Notification' : 'Details'}
          </h4>
          <button onClick={handelCancel} type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="#3F4949"
              />
            </svg>
          </button>
        </div>
        <div className="col-span-2">
          <div className="mb-4">
            <label htmlFor="title" className="soc-input-label focunLevel">
              <span className="focusSpan soc-input-label-span">Title</span>
              <input
                disabled={isLoading}
                readOnly={openDetails}
                required
                id="title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="soc-input"
              />
            </label>
          </div>
          <div className="mb-4">
            <label htmlFor="topic" className="soc-input-label focunLevel">
              <span className="focusSpan soc-input-label-span">Topic</span>
              <select
                disabled={isLoading}
                readOnly={openDetails}
                required
                id="topic"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                className="soc-input"
              >
                <option value="release">Release</option>
                <option value="general">General</option>
              </select>
            </label>
          </div>

          <div className="mb-4">
            <label htmlFor="message" className="soc-input-label focunLevel">
              <span className="focusSpan soc-input-label-span">Message</span>
              <textarea
                disabled={isLoading}
                readOnly={openDetails}
                required
                rows={3}
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="soc-input"
              />
            </label>
          </div>

          {!openDetails && (
            <div className="flex absolute bottom-0 right-0 w-full border-[#BEC8C9] border-t pt-5">
              <button
                disabled={isLoading}
                type="submit"
                className="soc-button flex items-center justify-center px-4 w-full"
              >
                <p className="mr-2">Send Notification </p>{' '}
                {isLoading && <Spinner className="w-4 h-4" />}{' '}
              </button>
            </div>
          )}
          {isError && <ErrorAlert message={error?.message || error?.data?.error} />}
        </div>
      </form>
    </div>
  );
};

export default CreateNotification;
