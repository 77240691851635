import React from 'react';
import DataTable from 'react-data-table-component';
import { useGetAllSubscriptionsQuery } from '../../features/subscription/subscriptionAPI';
import { dateFormat } from '../../uitls/dateFormat';
import Loader from '../Loader/Loader';
import ErrorAlert from '../Message/ErrorAlert';

const customStyles = {
  rows: {
    style: {
      color: '#191C1C',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      minHeight: '65px',
    },
  },
  headCells: {
    style: {
      color: '#191C1C',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '20px',
      background: '#DAE4E5',
      padding: '20px',
    },
  },
};
const columns = [
  {
    name: 'TxnId',
    selector: (row) => row.txnId,
    width: '240px',
    cell: (row) => (
      <p className="ml-3 text-soc-light-on-surface-variant font-medium text-sm">{row.txnId}</p>
    ),
  },
  {
    name: 'Purchase Date',
    selector: (row) => row.purchaseDate,
    width: '240px',
    cell: (row) => (
      <p className="ml-1 text-soc-light-on-surface-variant font-medium text-sm">
        {dateFormat(row.purchaseDate)}
      </p>
    ),
  },
  {
    name: 'Expiry Date',
    selector: (row) => row.expiresDate,
    width: '240px',
    cell: (row) => (
      <p className="ml-1 text-soc-light-on-surface-variant font-medium text-sm">
        {dateFormat(row.expiresDate)}
      </p>
    ),
  },
  {
    name: 'Plan',
    selector: (row) => row.subscriptionPackage,
    width: '240px',
    cell: (row) => (
      <p className="ml-1 text-soc-light-on-surface-variant font-medium text-sm">
        {row.subscriptionPackage?.toUpperCase()}
      </p>
    ),
  },
  {
    name: 'Price',
    selector: (row) => row.price,
    width: '240px',
    cell: (row) => (
      <p className="ml-1 text-soc-light-on-surface-variant font-medium text-sm">{row.price}</p>
    ),
  },
  {
    name: 'Currency',
    selector: (row) => row.currency,
    width: '240px',
    cell: (row) => (
      <p className="ml-1 text-soc-light-on-surface-variant font-medium text-sm">
        {row.currency.toUpperCase()}
      </p>
    ),
  },
  {
    name: 'Product ID',
    selector: (row) => row.productId,
    width: '240px',
    cell: (row) => (
      <p className="ml-1 text-soc-light-on-surface-variant font-medium text-sm">{row.productId}</p>
    ),
  },
  {
    name: 'User ID',
    selector: (row) => row.userId,
    width: '240px',
    cell: (row) => (
      <p className="ml-1 text-soc-light-on-surface-variant font-medium text-sm">{row.userId}</p>
    ),
  },
  {
    name: 'Device ID',
    selector: (row) => row.deviceId,
    width: '240px',
    cell: (row) => (
      <p className="ml-1 text-soc-light-on-surface-variant font-medium text-sm">{row.deviceId}</p>
    ),
  },
  {
    name: 'Platform',
    selector: (row) => row.platform,
    width: '240px',
    cell: (row) => (
      <p className="ml-1 text-soc-light-on-surface-variant font-medium text-sm">
        {row.platform?.toUpperCase()}
      </p>
    ),
  },
  {
    name: 'Status',
    selector: (row) => row.status,
    width: '240px',
    cell: (row) => (
      <p className="ml-1 text-soc-light-on-surface-variant font-medium text-sm">{row.status}</p>
    ),
  },
];

export const SubscriptionList = () => {
  const { data, isLoading, isError, error } = useGetAllSubscriptionsQuery();

  const subscriptionsListData = data?.subscriptions?.map((item) => {
    const content = {
      txnId: item.transactionId,
      purchaseDate: Number(item.purchaseDate),
      expiresDate: Number(item.expiresDate),
      deviceId: item.deviceId,
      platform: item.platform,
      subscriptionPackage: item.subscriptionPackageName,
      price: item.price,
      currency: item.currency,
      userId: item.userId,
      productId: item.productId,
      status: item.status || '-',
    };
    return content;
  });

  return (
    <>
      {isError && <ErrorAlert message={error?.message || error?.data?.error} />}
      <div className="border rounded-2xl border-soc-light-surface-outline-variant my-6">
        <DataTable
          defaultSortFieldId={1}
          // conditionalRowStyles={conditionalRowStyles}
          className="h-[72vh] overflow-y-scroll overflow-x-hidden"
          responsive
          fixedHeader
          columns={columns}
          data={subscriptionsListData}
          progressPending={isLoading}
          pagination
          progressComponent={
            <div className="my-10">
              <Loader />
            </div>
          }
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
        />
      </div>
    </>
  );
};
