import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  seriesDetails: {},
  isEpisodeShow: false,
};

const seriesSlice = createSlice({
  name: 'series',
  initialState,
  reducers: {
    setSeriesDetails: (state, action) => {
      state.seriesDetails = action.payload;
      state.isEpisodeShow = false;
    },
    removeSeriesDetails: (state) => {
      state.seriesDetails = {};
      state.isEpisodeShow = false;
    },
    setSeriesWithEpisodeDetails: (state, action) => {
      state.seriesDetails = action.payload;
      state.isEpisodeShow = true;
    },
    removeEpisodeShow: (state) => {
      state.isEpisodeShow = false;
    },
  },
});

export default seriesSlice;

export const {
  setSeriesDetails,
  removeSeriesDetails,
  setSeriesWithEpisodeDetails,
  removeEpisodeShow,
} = seriesSlice.actions;
