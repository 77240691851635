import { apiSlice } from '../api/apiSlice';

const subscriptionAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubscriptions: builder.query({
      query: () => ({
        url: '/admin/subscriptions',
        method: 'GET',
      }),
      providesTags: ['getAllSubscriptions'],
    }),
  }),
});

export const { useGetAllSubscriptionsQuery } = subscriptionAPI;
