/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../features/authentication/AuthenticationSlice';
import FullLogo from '../../resources/logo-full.svg';
import SubscriptionIcon from '../../resources/subscriptionIcon.svg';

export const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarOpen(false);
    }
  };

  const handleSidebarClick = (event) => {
    event.stopPropagation(); // Prevent the event from propagating to the handleOutsideClick
  };

  useEffect(() => {
    if (sidebarOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [sidebarOpen]);

  useEffect(() => {
    if (sidebarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [sidebarOpen]);

  const signOutHandler = () => {
    setSidebarOpen(false);
    document.body.classList.remove('sidebar-open');
    dispatch(logoutUser());
    navigate('/login');
  };

  return (
    <>
      <button
        ref={sidebarRef}
        onClick={toggleSidebar}
        // data-drawer-target="default-sidebar"
        // data-drawer-toggle="default-sidebar"
        // aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6 text-white"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          />
        </svg>
      </button>
      <aside
        onClick={handleSidebarClick}
        // id="default-sidebar"
        className={`z-[999999] fixed top-0 left-0 w-64 h-screen transition-transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="overflow-y-auto py-5 px-3 h-full bg-[#F2F4F4] rounded-r-2xl">
          <div className="ml-5 mb-5">
            <Link to="/assets" className="mb-5 flex items-center pl-2.5">
              <img alt="Flowbite logo" src={FullLogo} className="" />
              <span className="self-center whitespace-nowrap text-xl font-semibold" />
            </Link>
            <h2 className="text-soc-light-on-surface-variant font-semibold text-base">
              Administrative APP
            </h2>
          </div>

          <ul className="space-y-2">
            <li>
              <NavLink to="/assets" className="soc-sidebar-lnik group transition-all">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15.5 6V16.5C15.5 18.71 13.71 20.5 11.5 20.5C9.29 20.5 7.5 18.71 7.5 16.5V6C7.5 4.62 8.62 3.5 10 3.5C11.38 3.5 12.5 4.62 12.5 6V15.5C12.5 16.05 12.05 16.5 11.5 16.5C10.95 16.5 10.5 16.05 10.5 15.5V6H9V15.5C9 16.88 10.12 18 11.5 18C12.88 18 14 16.88 14 15.5V6C14 3.79 12.21 2 10 2C7.79 2 6 3.79 6 6V16.5C6 19.54 8.46 22 11.5 22C14.54 22 17 19.54 17 16.5V6H15.5Z"
                    fill="black"
                  />
                </svg>

                <span className="soc-sidebar-lnik-span">Assets</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/categories" className="soc-sidebar-lnik group transition-all">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-grid"
                >
                  <rect x="3" y="3" width="7" height="7" />
                  <rect x="14" y="3" width="7" height="7" />
                  <rect x="14" y="14" width="7" height="7" />
                  <rect x="3" y="14" width="7" height="7" />
                </svg>

                <span className="soc-sidebar-lnik-span">Categories</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/notification" className="soc-sidebar-lnik group transition-all">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2Z"
                    fill="#3F4949"
                  />
                </svg>

                <span className="soc-sidebar-lnik-span">Notifications</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/subscriptions" className="soc-sidebar-lnik group transition-all">
                <img src={SubscriptionIcon} alt="" style={{ width: '24px', height: '24px' }} />
                <span className="soc-sidebar-lnik-span">Subscriptions</span>
              </NavLink>
            </li>
          </ul>
          <ul className="pt-2 mt-5 space-y-2 border-t border-soc-light-surface-outline-variant">
            <li>
              <button
                type="button"
                onClick={signOutHandler}
                className="soc-sidebar-lnik group transition-all d-block w-full"
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Icon">
                    <mask
                      id="mask0_58_5126"
                      style={{ maskType: 'alpha' }}
                      maskUnits="userSpaceOnUse"
                      x={0}
                      y={0}
                      width={24}
                      height={24}
                    >
                      <rect id="Bounding box" width={24} height={24} fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_58_5126)">
                      <path
                        id="logout"
                        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H12V5H5V19H12V21H5ZM16 17L14.625 15.55L17.175 13H9V11H17.175L14.625 8.45L16 7L21 12L16 17Z"
                        fill="#3F4949"
                      />
                    </g>
                  </g>
                </svg>

                <span className="soc-sidebar-lnik-span">Log out</span>
              </button>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};
