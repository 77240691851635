import React from 'react';
import { SubscriptionList } from '../Components/Subscriptions/SubscriptionList';

export const Subscription = () => {
  return (
    <section className="container p-3 mx-auto">
      <div className="grid grid-cols- xl:grid-cols-3 gap-7">
        <div className="col-span-3 bg-[#F7FAF9] p-8 rounded-xl overflow-hidden relative">
          <div className="flex justify-between items-center pb-3">
            <h2 className="text-3xl font-normal text-soc-light-on-surface-variant">
              Subscriptions
            </h2>
          </div>
          <SubscriptionList />
        </div>
      </div>
    </section>
  );
};
