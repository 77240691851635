import React, { useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import getS3Content from '../../../uitls/getS3Content';
import Loader from '../../Loader/Loader';

const Image = ({ row, setImageData }) => {
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const img = await getS3Content(row.cover);
      setImage(img);
      setImageData(img);
      setLoading(false);
    })();
  }, [row.cover, setImageData]);

  return (
    <div>
      {loading ? (
        <ColorRing
          visible
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
        />
      ) : (
        <img
          className="h-16 w-16 rounded-md object-cover"
          src={loading ? <Loader /> : image}
          alt="imageCover"
        />
      )}
    </div>
  );
};

export default Image;
