/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { useGetAllNotificationQuery } from '../../features/notification/notificationAPI';
import { setNotificationDetails } from '../../features/notification/notificationSlice';
import { dateFormat } from '../../uitls/dateFormat';
import Loader from '../Loader/Loader';
import ErrorAlert from '../Message/ErrorAlert';

const customStyles = {
  rows: {
    style: {
      color: '#191C1C',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      minHeight: '65px',
    },
  },
  headCells: {
    style: {
      color: '#191C1C',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '20px',
      background: '#DAE4E5',
      padding: '20px',
    },
  },
};
const NotificationList = () => {
  const dispatch = useDispatch();
  const [selectRowId, setSelectedRowId] = useState(null);

  const { data, isLoading, isError, error } = useGetAllNotificationQuery();

  const handelDetails = ({ ...details }) => {
    setSelectedRowId(details?.NotificationId);
    dispatch(setNotificationDetails(details));
  };

  const conditionalRowStyles = [
    {
      when: (row) => row?.id === selectRowId,
      style: {
        backgroundColor: '#ECEEEE',
      },
    },
  ];
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.title.toLowerCase();
    const b = rowB.title.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const columns = [
    {
      name: 'Title',
      sortFunction: caseInsensitiveSort,
      cell: (row) => (
        <div onClick={() => handelDetails({ ...row })}>
          <p className="text-soc-light-on-surface-variant font-medium text-sm">{row.title}</p>
        </div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: 'Notification',
      cell: (row) => (
        <div onClick={() => handelDetails({ ...row })}>
          <p className="text-soc-light-on-surface-variant font-medium text-sm">{row?.message}</p>
        </div>
      ),
      selector: (row) => row.message,
      sortable: true,
      width: '270px',
    },
    {
      name: 'Date Sent',
      cell: (row) => (
        <div onClick={() => handelDetails({ ...row })}>
          <p className="text-soc-light-on-surface-variant font-medium text-sm">
            {dateFormat(row?.dateTime)}
          </p>
        </div>
      ),
      selector: (row) => row.dateTime,
      sortable: true,
    },
  ];

  const notificationData = data?.data?.Items?.map((item) => {
    const content = {
      id: item?.NotificationId,
      dateTime: new Date(item.datetime),
      ...item,
    };
    return content;
  });

  return (
    <>
      {isError && <ErrorAlert message={error?.message || error?.data?.error} />}
      <div className="border rounded-2xl border-soc-light-surface-outline-variant my-6">
        <DataTable
          onRowClicked={(row) => handelDetails({ ...row })}
          defaultSortFieldId={2}
          defaultSortAsc={false}
          conditionalRowStyles={conditionalRowStyles}
          className="h-[72vh] overflow-y-scroll overflow-x-hidden"
          responsive
          fixedHeader
          columns={columns}
          data={notificationData}
          progressPending={isLoading}
          // pagination
          progressComponent={
            <div className="my-10">
              <Loader />
            </div>
          }
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
        />
      </div>
    </>
  );
};

export default NotificationList;
