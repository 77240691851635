/* eslint-disable */
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import axios from "axios";
import { Spinner } from "flowbite-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Modal from "../Components/Categories/Modal";
import ErrorAlert from "../Components/Message/ErrorAlert";
import * as variables from '../config/variable';
import { setCategories } from "../features/assets/categoriesSlice";
const initialFormData = {
  id: null,
  category: "",
  order: 0,
  isSeries: false,
};
const Categories = () => {
  const [editCover, isEditCover] = useState(false);
  const { categories } = useSelector((state) => state.categories);
  // console.log("categories: ",categories);
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.authentication);
  const [seriesCover, setSeriesCover] = useState(null);
  const [seriesCoverImageInfo, setSeriesCoverImageInfo] = useState({
    name: '',
    type: '',
  });
  const onChangeSeriesCoverFile = (files) => {
    // console.log("files: ",files);
    if (files?.length > 0) {
      setSeriesCoverImageInfo({ name: files[0].file?.name, type: files[0].file?.type });
      setSeriesCover(files[0].file);
    }
  };

  
  const seriesCoverOnRemove = () => {
    setSeriesCover(null);
  };
  const editCoverPondRef = useRef(null);
  const [categoryDetails, setCategoryDetails] = useState({});
  const [seriesDescription, setSeriesDescription] = useState('');
  const [isSeries, setIsSeries] = useState(false);
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const [distinctGroups, setDistinctGroups] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const hasFetchedCategories = useRef(false);
  
  useEffect(() => {
    return () => {
      hasFetchedCategories.current = false;
    }
  }, []);
  useEffect(() => {
    if (Object.values(categoryDetails).length > 0 && !seriesCover) {
      isEditCover(false);
    } else {
      isEditCover(true);
    }
  }, [categoryDetails, seriesCover])
  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleInputChange = useCallback((e) => {
    e.preventDefault();
    // console.log("e.target",e.target);
    const { name, value ,type, checked,} = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    // console.log("e.target",{ name, value,type, newValue });
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue
    }));
  }, []);

  useEffect(() => {
    return () => {
      setIsError(false);
      setError("");
    };
  }, []);

  const fetchData = async (shouldLoading = true) => {
    setIsError(false);
    setError("");
    setLoading(true);
    return axios
      .get(`${variables.apiUrl}/admin/category/list`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        //console.log("response.data: ",response?.data?.categories);
        dispatch(setCategories(response?.data?.categories || []));
        setRowData(response?.data?.categories);
        setDistinctGroups(extractDistinctGroups(response?.data?.categories))
        gridRef.current.api.sizeColumnsToFit();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setIsError(true);
        setError("Error fetching category list");
        toast.error("Error fetching category list");
      });
  };

  const extractDistinctGroups = (categories) => {
    const maxGroup = categories.reduce((max, category) => {
      return category.group ? Math.max(max, category.group) : max;
    }, 0);
  
    return Array.from({ length: maxGroup }, (_, i) => i + 1);
  };

  const handleAdd = useCallback(() => {
    setIsEditing(false);
    setCategoryDetails({});
    setIsSeries(false);
    setSeriesDescription("");
    setSeriesCover(null);
    setSeriesCoverImageInfo({
      name: '',
      type: '',
    });
    isEditCover(false);
    setFormData((prevState) => ({
      ...prevState,
      ...initialFormData,
      group: distinctGroups.length > 0 ? distinctGroups[0] : 1,
    }));
    handleOpenModal();
  }, []);

  const handleEdit = useCallback(
    (data) => {
      if (data) {
        setIsEditing(true);
        setFormData((prevState) => ({
          ...prevState,
          ...data
        }));
        setCategoryDetails(data);
        setIsSeries(data.isSeries);
        setSeriesDescription(data.seriesDescription);
        setSeriesCover(null);
        setSeriesCoverImageInfo({
          name: '',
          type: ''
        });
        handleOpenModal();
      }
    },
    [handleOpenModal]
  );

  const colors = [
    '#FFD700', '#FFB6C1', '#87CEFA', '#90EE90', '#FFDEAD', '#FF69B4', '#AFEEEE', '#E6E6FA',
    '#FFFACD', '#D8BFD8', '#ADD8E6', '#E0FFFF', '#FFE4E1', '#F0E68C', '#FF6347', '#98FB98',
    '#FFC0CB', '#FFEFD5', '#FFB347', '#E0FFFF'
  ];
  
  const getColorForGroup = (group) => {
    return colors[(group - 1) % colors.length];
  };

  const ActionCellRenderer = ({ data }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const handleDelete = useCallback(async () => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsDeleting(true);
          setIsError(false);
          setError("");
          try {
            await axios.delete(`${variables.apiUrl}/admin/category/${data.id}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            });
            //toast.success("Category deleted successfully");
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Category was successfully deleted",
              showConfirmButton: false,
              timer: 1500
            });

            await fetchData(); // Refresh the grid data
          } catch (error) {
            // console.log("error:",error?.response?.data?.message);
            setIsError(true);
            setError(error?.response?.data?.message || "Error deleting category");
            toast.error(error?.response?.data?.message || "Error deleting category");
          } finally {
            setIsDeleting(false);
          }
        }
      });
    }, [data.id]);
    const handleView = useCallback(
      () => {
        // console.log("data: ",data.category);
        if (data.category) {
          navigate(`/assets?categoryId=${data.id}`);
        }
      },
      [data.category]
    );
    return (
      <div>
        <button
          onClick={(e) => handleView(data)}
          className="inline-flex items-center p-1 rounded-full hover:bg-[#49454f24] transition-all"
          type="button"
          title="View Assets"
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10" />
              <line x1="12" y1="16" x2="12" y2="12" />
              <line x1="12" y1="8" x2="12" y2="8" />
          </svg>
        </button>
        <button
          onClick={() => handleEdit(data)}
          className="inline-flex items-center p-1 rounded-full hover:bg-[#49454f24] transition-all"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.06 3.79008L20.41 5.14008C21.2 5.92008 21.2 7.19008 20.41 7.97008L7.18 21.2001H3V17.0201L13.4 6.61008L16.23 3.79008C17.01 3.01008 18.28 3.01008 19.06 3.79008ZM5 19.2001L6.41 19.2601L16.23 9.43008L14.82 8.02008L5 17.8401V19.2001Z"
              fill="#3F4949"
            />
          </svg>
        </button>
        {isDeleting && (
          <>
            <div className="inline-flex items-center align-super">
              <Spinner color="pink" aria-label="Deleting" />
            </div>
          </>
        )}
        {!isDeleting && (
          <button
            onClick={handleDelete}
            disabled={isDeleting}
            className="inline-flex items-center p-1 rounded-full hover:bg-[#49454f24] transition-all"
            type="button"
            title="Remove Category"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="15" y1="9" x2="9" y2="15" />
                <line x1="9" y1="9" x2="15" y2="15" />
              </svg>
          </button>
        )}
      </div>
    );
  };
  const ActionHeaderRenderer = (props) => {
    return (
      <div className="textAlignCenter">Actions</div>
    );
  };

  const CategoryNameRenderer = (props) => {
    const { value, data } = props;
    const groupColor = data.group ? getColorForGroup(data.group) : '#FFFFFF';
    return (
      <div className="flex items-center">
        <span className="font-medium">{value}</span>
        {data.group && (
        <span 
          className="ml-2 px-2 py-1 text-xs font-semibold rounded-full"
          style={{
            backgroundColor: groupColor,
          }}
        >
          Group {data.group}
        </span>
      )}
      </div>
    );
  };

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    {
      headerName: "Name",
      field: "category",
      // checkboxSelection: true,
      flex: 2,
      minWidth: 250,
      filter: false,
      floatingFilter: false,
      rowDrag: true,
      wrapText: true, // Wrap Text
      autoHeight: true, // Adjust Cell Height to Fit Wrapped Text
      cellRenderer: CategoryNameRenderer
    },
    {
      headerComponent: ActionHeaderRenderer,
      cellRenderer: ActionCellRenderer,
      // cellRendererParams: {
      //   accessToken: accessToken,
      //   fetchData: fetchData // Assuming fetchData is a function to refresh the grid data
      // },
      sortable: false,
      resizable: false,
      cellStyle: { justifyContent: 'center' },
     
    }
  ]);
  const rowSelection = "single";
  const gridOptions = useMemo(
    () => ({
      defaultColDef: {
        resizable: true,
        sortable: true
      },
      columnDefs: colDefs,
      rowData
    }),
    [colDefs, rowData]
  );
  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    floatingFilter: false,
    filter: false,
    editable: false,
    sortable: false,
    resizable: false,
    wrapText: true,
    autoHeight: true,
    suppressMovable: false,
    suppressHorizontalScroll: true,
    suppressCellSelection: true,
    cellStyle: { display: 'flex', alignItems: 'center'}
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    gridRef.current.api.sizeColumnsToFit();
  };

  useEffect(() => {
    if(gridApi && accessToken && rowData.length === 0 && !hasFetchedCategories.current){
      fetchData();
      hasFetchedCategories.current = true;
    }
  }, [accessToken, gridApi, rowData])
  const preSigndUrl = async () => {
    const url = `${variables.apiUrl}/admin/asset/upload/url`;
    const seriesCoverData = isSeries === true 
        ? { name: seriesCoverImageInfo.name, type: seriesCoverImageInfo.type }
        : undefined;
    const data = {
      seriesCover: seriesCoverData,
    };
    const response = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then(response => response.data);
    return response;
  };
  // Handle Save
  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSubmitLoader(true);
    setIsError(false);
    setError("");
    const postData = {...formData}
    // console.log("formData: ",formData);
    // console.log("seriesCover: ",seriesCover);
    // console.log("seriesCoverImageInfo: ",seriesCoverImageInfo);
    // console.log("seriesDescription: ",seriesDescription);
    // console.log("isSeries: ",isSeries);
    // return;
    if(!isEditing && isSeries === true && (String(formData.category).trim() === "" || String(seriesDescription).trim() === "" || seriesCover === null || Object.values(seriesCoverImageInfo).length === 0)) {
      setSubmitLoader(false);
      alert("Series name, description and cover is required");
      return;
    }
    else if(isEditing && isSeries === true && (String(formData.category).trim() === "" || String(seriesDescription).trim() === "" )) {
      setSubmitLoader(false);
      alert("Series name, description and cover is required");
      return;
    }
    else if(String(formData.category).trim() === "" ) {
      setSubmitLoader(false);
      alert("Category name is required");
      return;
    }
    try{
      if(isSeries === true) {
        let preSgindResponse = {};
        if(seriesCoverImageInfo.name  && seriesCoverImageInfo.type){
          preSgindResponse = await preSigndUrl();
          if (preSgindResponse.seriesCoverUrl) {
            await axios.put(preSgindResponse.seriesCoverUrl, seriesCover, {
              headers: {
                'Content-Type': seriesCoverImageInfo.type,
              },
            });
          }
        }
        // console.log("handleSave preSgindResponse: ",preSgindResponse);
        if(preSgindResponse.seriesCoverUrl){
          postData.seriesCover = preSgindResponse.seriesCoverUrl;
        }
        if(seriesDescription){
          postData.seriesDescription = seriesDescription
        }
        postData.isSeries = isSeries;
       
        // console.log("handleSave postData: ",postData);
        if (isEditing && postData.id) {
          await axios.put(`${variables.apiUrl}/admin/category/${postData.id}`, {
            ...postData
          },{
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          toast.success("Category updated successfully");
        }else{
          await axios.post(`${variables.apiUrl}/admin/category`, postData, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          toast.success("Category created successfully");
        }
        await fetchData();
      }else{
        if(seriesDescription){
          postData.seriesDescription = seriesDescription
        }
        postData.isSeries = isSeries;
        if (isEditing && postData.id) {
          await axios.put(`${variables.apiUrl}/admin/category/${postData.id}`, {
            ...postData
          },{
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          toast.success("Category updated successfully");
        }else{
            await axios.post(`${variables.apiUrl}/admin/category`, postData, {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            });
            toast.success("Category created successfully");
        }
        await fetchData();
      }
    }catch(error){
      setIsError(true);
      setError("Error saving category");
      toast.error("Error saving category");
    }
    finally {
      setIsEditing(false);
      setSubmitLoader(false);
      handleCloseModal();
      setFormData((prevState) => ({
        ...prevState,
        ...initialFormData
      }));
      setSeriesCover(null);
      setSeriesCoverImageInfo({
        name: '',
        type: '',
      });
      setSeriesDescription("");
      setIsSeries(false);
    }
    // try {
    //   if (isEditing && formData.category && formData.id) {
    //     await axios.put(`${variables.apiUrl}/admin/category/${formData.id}`, {
    //       ...formData
    //     },{
    //       headers: {
    //         Authorization: `Bearer ${accessToken}`
    //       }
    //     });
    //     toast.success("Category updated successfully");
    //     await fetchData();
    //   } else {
    //     await axios.post(`${variables.apiUrl}/admin/category`, formData, {
    //       headers: {
    //         Authorization: `Bearer ${accessToken}`
    //       }
    //     });
    //     toast.success("Category created successfully");
    //     await fetchData();
    //   }
    // } catch (error) {
    //   setIsError(true);
    //   setError("Error saving category");
    //   toast.error("Error saving category");
    // } finally {
    //   setIsEditing(false);
    //   setSubmitLoader(false);
    //   handleCloseModal();
    //   setFormData((prevState) => ({
    //     ...prevState,
    //     ...initialFormData
    //   }));
    
    // }
  };
  const onRowDragEnd = async (event) => {
    try {
      setLoading(true);
      const sortedRowData = [];
      const allRows = event.api.getDisplayedRowCount();
      for (let i = 0; i < allRows; i++) {
        const rowNode = event.api.getDisplayedRowAtIndex(i);
        if (rowNode.data && typeof rowNode.data === 'object') {
          sortedRowData.push({
            ...rowNode.data,
            order: i + 1
          });
        }
      }
      await axios.put(`${variables.apiUrl}/admin/category`, {order: [...sortedRowData]}, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    } catch (error) {
      // console.log("onRowDragEnd", error);
      toast.error("Error updating category order");
    } finally {
      toast.success("Category order updated successfully");
    }
    fetchData(false);
  };
  const addNewCategory = (
    <button
      onClick={handleAdd}
      type="button"
      className="soc-button w-auto flex items-center justify-center px-4"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={19}
        viewBox="0 0 18 19"
        fill="none"
      >
        <path
          d="M15 9.78271H9.75V15.0327H8.25V9.78271H3V8.28271H8.25V3.03271H9.75V8.28271H15V9.78271Z"
          fill="white"
        />
      </svg>
      <span className="mx-2">Add Category</span>
    </button>
  );

  const getRowStyle = (params) => {
    const groupColor = params.data.group ? getColorForGroup(params.data.group) : '#FFFFFF';
    
    return {
      borderLeft: `6px solid ${groupColor}`
    };
  };
  

  return (
    <section className="container p-3 mx-auto">
      <div className="grid grid-cols- xl:grid-cols-3 gap-7">
        <div className="col-span-2 bg-[#F7FAF9] p-8 rounded-xl overflow-hidden relative">
          <div className="flex justify-between items-center pb-3">
            <h2 className="text-3xl font-normal text-soc-light-on-surface-variant">Categories</h2>
            {addNewCategory}
          </div>
          
          {isError && <div><ErrorAlert message={error} /></div>}
          
            <div
              className="ag-theme-quartz" 
              style={{ height: 500, marginTop: "10px", overflowY: "auto" }} 
             
           >
              <AgGridReact
                style={{ width: "100%", height: "100%" }}
                ref={gridRef}
                onGridReady={onGridReady}
                overlayNoRowsTemplate="<span>There are no categories to display.</span>"
                overlayLoadingTemplate='<span className="ag-overlay-loading-center">Please wait while your categories are loading...</span>'
                loading={loading}
                columnDefs={colDefs}
                rowData={rowData}
                // domLayout="autoHeight"
                gridOptions={gridOptions}
                defaultColDef={defaultColDef}
                rowSelection={rowSelection}
                rowBuffer={30}
                rowDragManaged
                getRowStyle={getRowStyle} 
                onRowDragEnd={onRowDragEnd} // Attach the row drag end event
              />
            </div>
          </div>
        </div>
      <Modal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        handleSave={handleSave}
        submitLoader={submitLoader}
        formData={formData}
        setFormData={setFormData}
        handleInputChange={handleInputChange}
        onChangeSeriesCoverFile={onChangeSeriesCoverFile}
        seriesCoverOnRemove={seriesCoverOnRemove}
        isSeries={isSeries}
        setIsSeries={setIsSeries}
        seriesDescription={seriesDescription}
        setSeriesDescription={setSeriesDescription}
        categoryDetails={categoryDetails}
        isEditing={isEditing}
        editCoverPondRef={editCoverPondRef}
        editCover={editCover}
        distinctGroups={distinctGroups}
        setDistinctGroups={setDistinctGroups}
      />
    </section>
  );
};

export default Categories;
