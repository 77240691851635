/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import './Audio.scss';

const AudioPlayer2 = ({ audioFile, playerRef }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioSrc = audioFile;

  const playIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="22"
      height="22"
      fill="#00696E"
      stroke="#fff"
      viewBox="0 0 191.255 191.255"
    >
      <path d="M162.929 66.612a6 6 0 1 0-6.35 10.183c6.544 4.081 10.45 11.121 10.45 18.833s-3.906 14.752-10.45 18.833l-98.417 61.365c-6.943 4.329-15.359 4.542-22.512.573-7.154-3.97-11.425-11.225-11.425-19.406V34.262c0-8.181 4.271-15.436 11.425-19.406 7.153-3.969 15.569-3.756 22.512.573l57.292 35.723a6 6 0 1 0 6.35-10.183L64.512 5.247c-10.696-6.669-23.661-7-34.685-.883-11.021 6.116-17.601 17.293-17.601 29.898v122.73c0 12.605 6.58 23.782 17.602 29.898 5.25 2.913 10.939 4.364 16.616 4.364 6.241 0 12.467-1.754 18.068-5.247l98.417-61.365c10.082-6.287 16.101-17.133 16.101-29.015s-6.019-22.728-16.101-29.015z" />
    </svg>
  );

  const pauseIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      stroke="#00696E"
      viewBox="0 0 24 24"
    >
      <path
        d="M15 16v2m0-12v6M9 6v12"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );

  const soundIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#e0e3e3">
      <path
        fillRule="evenodd"
        d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
        clipRule="evenodd"
      />
    </svg>
  );

  const muteIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#e0e3e3">
      <path
        fillRule="evenodd"
        d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );

  const toggleAudio = () => {
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleSound = () => {
    playerRef.current.muted = !muted;
    setMuted(!muted);
  };

  const changeTimelinePosition = () => {
    const percentagePosition = (100 * playerRef.current.currentTime) / playerRef.current.duration;
    setCurrentTime(percentagePosition);
  };

  const changeSeek = (event) => {
    const time = (event.target.value * playerRef.current.duration) / 100;
    playerRef.current.currentTime = time;
    setCurrentTime(event.target.value);
  };

  // useEffect(() => {
  //   const audioElement = playerRef.current;

  //   audioElement.addEventListener('timeupdate', changeTimelinePosition);
  //   audioElement.addEventListener('ended', () => {
  //     setIsPlaying(false);
  //     setCurrentTime(0);
  //   });

  //   return () => {
  //     audioElement.removeEventListener('timeupdate', changeTimelinePosition);
  //     audioElement.removeEventListener('ended', () => {
  //       setIsPlaying(false);
  //       setCurrentTime(0);
  //     });
  //   };
  // }, []);

  return (
    <div className="audio-player bg-[#2D3131] p-4 rounded shadow-md">
      {/* <video ref={playerRef} src={audioSrc} controls /> */}
      <ReactHlsPlayer
        playerRef={playerRef}
        src={audioSrc}
        hlsConfig={{
          maxLoadingDelay: 4,
          minAutoBitrate: 0,
          lowLatencyMode: true,
        }}
        controls
        width="100%"
        height="100px"
        crossOrigin="anonymous"
      />
      {/* <div className="controls">
        <button type="button" className="player-button" onClick={toggleAudio}>
          {isPlaying ? pauseIcon : playIcon}
        </button>
        <input
          step="any"
          type="range"
          className="timeline"
          max="100"
          value={currentTime}
          style={{ backgroundSize: `${currentTime}%` }}
          onChange={changeSeek}
        />
        <button type="button" className="sound-button" onClick={toggleSound}>
          {muted ? muteIcon : soundIcon}
        </button>
      </div> */}
    </div>
  );
};

export default AudioPlayer2;
