/* eslint-disable jsx-a11y/label-has-associated-control */
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { Spinner } from 'flowbite-react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserPoll from '../../config/cognitoConfig';
import { setUser } from '../../features/authentication/AuthenticationSlice';
import ErrorAlert from '../Message/ErrorAlert';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setSetPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoginInProgress, setIsLogingInProgress] = useState(false);

  const disPatch = useDispatch();
  const navigate = useNavigate();

  const handelLogin = (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setIsLogingInProgress(true);
    if (email === '' || password === '') {
      setIsLogingInProgress(false);
      setErrorMessage('Email and password are required');
    } else {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPoll,
      });

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: async (data) => {
          // console.log(`congnito data:::`, data);

          const accessToken = data.getAccessToken().getJwtToken();
          const refreshToken = data.getRefreshToken().getToken();
          let idToken = '';

          UserPoll.getCurrentUser().getSession((err, session) => {
            idToken = session?.idToken?.jwtToken;
          });

          disPatch(
            setUser({
              userId: data?.accessToken?.payload?.username,
              accessToken,
              idToken,
              refreshToken,
            })
          );
          navigate('/assets');
        },

        onFailure: (err) => {
          // console.error('onFailure:', err?.message);
          setErrorMessage(err?.message);
          setIsLogingInProgress(false);
        },

        newPasswordRequired: (data) => {
          delete data.email_verified;
          delete data.phone_number_verified;
          delete data.email;
          user.completeNewPasswordChallenge(password, data, this);
          setIsLogingInProgress(false);
        },
      });
    }
  };
  return (
    <form className="space-y-4 md:space-y-6" onSubmit={handelLogin}>
      <div>
        <label htmlFor="email" className="soc-input-label focunLevel">
          <span className="focusSpan soc-input-label-span">Email</span>
          <input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="email"
            name="email"
            id="email"
            className="soc-input"
            placeholder="Your email"
          />
        </label>
      </div>
      <div>
        <label htmlFor="password" className="soc-input-label focunLevel">
          <span className="soc-input-label-span focusSpan">Password</span>
          <input
            onChange={(e) => setSetPassword(e.target.value)}
            value={password}
            type="password"
            name="password"
            id="password"
            placeholder="********"
            className="soc-input"
          />
        </label>
      </div>

      <button disabled={isLoginInProgress} type="submit" className="soc-button">
        Sign in {isLoginInProgress && <Spinner className="w-4 h-4" />}
      </button>
      {errorMessage && <ErrorAlert message={errorMessage} />}
    </form>
  );
};

export default LoginForm;
