/* eslint-disable no-unused-vars */
import AWS from 'aws-sdk';

const getS3Content = async (url) => {
  return url;
  // AWS.config.update({
  //   region: process.env.REACT_APP_IDENTITY_POOL_ID.split(':')[0],
  // });

  // // Specify your Cognito Identity Pool ID
  // const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;
  // const credentials = new AWS.CognitoIdentityCredentials({
  //   IdentityPoolId: identityPoolId,
  // });

  // AWS.config.credentials = credentials;

  // // Create an S3 instance
  // const s3 = new AWS.S3();
  // // console.log({ url });
  // const urlParts = url?.split('/');
  // const bucketName = urlParts[urlParts.length - 3];
  // const objectKey = `${urlParts[urlParts.length - 2]}/${urlParts[urlParts.length - 1]}`;
  // // Prepare the parameters for the getObject operation
  // const params = {
  //   Bucket: bucketName,
  //   Key: objectKey,
  // };
  // // Call the getObject operation
  // return new Promise((resolve, reject) => {
  //   s3.getObject(params, (err, data) => {
  //     if (err) {
  //       console.error('s3 err', err);
  //       return resolve('');
  //     }
  //     // const objUrl = URL.createObjectURL(data.Body);
  //     // console.log({ s3Data: data });
  //     return resolve(`data:${data.ContentType};base64, ${data.Body.toString('base64')}`);
  //   });
  // });
};

export default getS3Content;
