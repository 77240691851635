/* eslint-disable no-undef */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dropdown } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import {
  useDeleteAssetMutation,
  useGetAllAssetsQuery,
  useUpdateStatusAssetsMutation,
} from '../../features/assets/assetsAPI';
import { setAssetDetails } from '../../features/assets/assetsSlice';
import { setSeriesDetails, setSeriesWithEpisodeDetails } from '../../features/assets/seriesSlice';
import Loader from '../Loader/Loader';
import ErrorAlert from '../Message/ErrorAlert';
import Image from './components/Image';

const customStyles = {
  rows: {
    style: {
      color: '#191C1C',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      minHeight: '90px',
    },
    // stripedStyle: {
    //   color: 'red',
    //   backgroundColor: 'red',
    // },
  },
  headCells: {
    style: {
      color: '#191C1C',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '20px',
      background: '#DAE4E5',
      padding: '20px',
    },
  },
  cells: {
    style: {
      // fontWeight: 'bold',
    },
  },
};
// const clickableStyle = {
//   color: '#007379',
//   cursor: 'pointer',
//   transition: 'color 0.3s, text-decoration 0.3s',
// };

const AssetsList = ({ setCloseDetails }) => {
  const [imageData, setImageData] = useState(null);

  const dispatch = useDispatch();

  const [selectRowId, setSelectedRowId] = useState(null);

  const conditionalRowStyles = [
    {
      when: (row) => row?.id === selectRowId,
      style: {
        backgroundColor: '#ECEEEE',
      },
    },
  ];

  const handelDetails = ({
    title,
    type,
    description,
    cover,
    hidden,
    AssetId,
    content,
    appleStoreId,
    playStoreId,
    assetDuration,
    isPremium,
    tag,
    seriesName,
    seriesDescription,
    seriesCover,
    episodNo,
    isSeries,
  }) => {
    setSelectedRowId(AssetId);
    dispatch(
      setAssetDetails({
        title,
        type,
        description,
        cover: imageData[cover],
        AssetId,
        imageInfo: cover,
        content,
        hidden,
        appleStoreId,
        playStoreId,
        assetDuration,
        isPremium,
        tag,
        seriesName,
        seriesDescription,
        seriesCover,
        episodNo,
        isSeries,
      })
    );
  };

  const { data, error, isError, isLoading } = useGetAllAssetsQuery(
    { allasset: 'true' },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      pollingInterval: 1200000, // 20 minutes interval
    }
  );

  const [updateStatusAssets, { isSuccess, error: statusError }] = useUpdateStatusAssetsMutation();
  const [updeleteAssetdateStatusAssets, { isSuccess: isAssetDelete, error: assetDeleteError }] =
    useDeleteAssetMutation();

  const handelOnUpdate = ({ assetId, value }) => {
    updateStatusAssets({ assetId, value });
  };

  useEffect(() => {
    if (isSuccess) {
      setCloseDetails(true);
      toast.success('Asset status updated successfully');
    }
    if (statusError) {
      toast.error('Asset status update failed');
    }
  }, [isSuccess, setCloseDetails, statusError]);

  const deleteHandler = (assetId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        updeleteAssetdateStatusAssets(assetId);
      }
    });
  };
  const updateSeriesHandler = ({ assetId, seriesName, seriesDescription, seriesCover }) => {
    dispatch(
      setSeriesDetails({
        assetId,
        seriesName,
        seriesDescription,
        seriesCover,
      })
    );
  };
  const seriesWithEpisodeHandler = ({ assetId, seriesName, seriesDescription, seriesCover }) => {
    dispatch(
      setSeriesWithEpisodeDetails({
        assetId,
        seriesName,
        seriesDescription,
        seriesCover,
      })
    );
  };
  useEffect(() => {
    if (isAssetDelete) {
      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Asset was successfully deleted',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (assetDeleteError) {
      toast.error('Error deleting asset');
    }
  }, [assetDeleteError, isAssetDelete]);

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.title.toLowerCase();
    const b = rowB.title.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const columns = [
    {
      name: 'Asset Title',
      selector: (row) => row.assetTitle,
      sortable: true,
      width: '270px',
      sortFunction: caseInsensitiveSort,
      cell: (row) => (
        <div
          className="flex items-center"
          onClick={() =>
            handelDetails({
              ...row,
            })
          }
        >
          <Image imageData={imageData} setImageData={setImageData} row={row} />
          <h2 className="ml-3 text-soc-light-on-surface-variant font-medium text-sm">
            {row.title}
          </h2>
        </div>
      ),
    },
    {
      name: 'Category',
      selector: (row) => row.tag,
      sortable: true,
      cell: (row) => {
        // const categoryNames = getCategoryNamesByIds(row.tag || '') || [];
        const categoryNames = row.tag ? row.tag.split(',') : [];
        return (
          <div
            onClick={() =>
              String(row.tag).toLowerCase() === 'series' && row.seriesName && row.episodNo
                ? seriesWithEpisodeHandler({
                    ...row,
                  })
                : handelDetails({
                    ...row,
                  })
            }
          >
            <h2 className="ml-1 capitalize font-medium text-sm">
              <span>
                {categoryNames.map((item) => String(item).toUpperCase()).join(',')}{' '}
                {row.episodNo && (
                  <>
                    ({/* <span style={clickableStyle}>{row.seriesName}</span> */}
                    {`EP ${row.episodNo}`})
                  </>
                )}
              </span>
            </h2>
          </div>
        );
      },
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div
          onClick={() =>
            handelDetails({
              ...row,
            })
          }
        >
          {row.hidden === false ? (
            <p className="bg-soc-light-surface-secondary-container text-soc-light-surface-on-secondary-container px-2 py-1 font-semibold rounded-lg text-center">
              Published
            </p>
          ) : (
            <p className="bg-soc-light-surface-error-container text-soc-light-surface-on-error-container px-2 py-1 font-semibold rounded-lg text-center">
              Unpublished
            </p>
          )}
        </div>
      ),
      width: '135px',
    },
    {
      name: 'Action',
      // ignoreRowClick: true,
      cell: (row) => (
        <div className="flex">
          <button
            className="inline-flex items-center p-1 rounded-full hover:bg-[#49454f24] transition-all"
            type="button"
            onClick={() =>
              handelDetails({
                ...row,
              })
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.06 3.79008L20.41 5.14008C21.2 5.92008 21.2 7.19008 20.41 7.97008L7.18 21.2001H3V17.0201L13.4 6.61008L16.23 3.79008C17.01 3.01008 18.28 3.01008 19.06 3.79008ZM5 19.2001L6.41 19.2601L16.23 9.43008L14.82 8.02008L5 17.8401V19.2001Z"
                fill="#3F4949"
              />
            </svg>
          </button>

          <div className="p-1 rounded-full hover:bg-[#49454f24] transition-all">
            <Dropdown
              arrowIcon={false}
              size="sm"
              color="#3F4949"
              renderTrigger={() => (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 8.2002C13.1 8.2002 14 7.3002 14 6.2002C14 5.1002 13.1 4.2002 12 4.2002C10.9 4.2002 10 5.1002 10 6.2002C10 7.3002 10.9 8.2002 12 8.2002ZM12 10.2002C10.9 10.2002 10 11.1002 10 12.2002C10 13.3002 10.9 14.2002 12 14.2002C13.1 14.2002 14 13.3002 14 12.2002C14 11.1002 13.1 10.2002 12 10.2002ZM10 18.2002C10 17.1002 10.9 16.2002 12 16.2002C13.1 16.2002 14 17.1002 14 18.2002C14 19.3002 13.1 20.2002 12 20.2002C10.9 20.2002 10 19.3002 10 18.2002Z"
                    fill="#3F4949"
                  />
                </svg>
              )}
            >
              {String(row.tag).toLowerCase() === 'series' && row.seriesName && row.episodNo && (
                <Dropdown.Item
                  onClick={() =>
                    updateSeriesHandler({
                      ...row,
                    })
                  }
                >
                  Update Series
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() =>
                  handelOnUpdate({
                    assetId: row.AssetId,
                    value: row.hidden === false ? 'true' : 'false',
                  })
                }
              >
                {row.hidden === true ? 'Publish' : 'Unpublish'}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => deleteHandler(row.AssetId)}>Delete</Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // const sortedAssets = structuredClone(data?.data?.Items)?.sort((a, b) =>
  //   a?.title?.toLowerCase()?.localeCompare(b?.title?.toLowerCase())
  // );

  const assetsData = data?.data?.Items?.map(
    ({
      title,
      type,
      description,
      hidden,
      cover,
      AssetId,
      content,
      appleStoreId,
      playStoreId,
      assetDuration,
      tag,
      seriesName,
      isPremium,
      seriesDescription,
      seriesCover,
      episodNo,
      isSeries,
    }) => {
      const renderContent = {
        id: AssetId,
        assetTitle: title,
        assetType: type || 'None',
        status: hidden === 'false' ? 'false' : 'true',
        title,
        type,
        description,
        hidden,
        cover,
        AssetId,
        content,
        appleStoreId,
        playStoreId,
        assetDuration,
        isPremium,
        tag,
        seriesName,
        seriesDescription,
        seriesCover,
        episodNo,
        isSeries,
      };
      return renderContent;
    }
  );

  return (
    <>
      {isError && <ErrorAlert message={error?.message || error?.data?.error} />}
      <div className="border rounded-2xl border-soc-light-surface-outline-variant my-6">
        <DataTable
          defaultSortFieldId={1}
          conditionalRowStyles={conditionalRowStyles}
          className="h-[72vh] overflow-y-scroll overflow-x-hidden"
          responsive
          fixedHeader
          columns={columns}
          data={assetsData}
          progressPending={isLoading}
          // pagination
          progressComponent={
            <div className="my-10">
              <Loader />
            </div>
          }
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
        />
      </div>
    </>
  );
};

export default AssetsList;
