/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
// import 'flowbite';
import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { PrivateLayout } from './Components/Layout/PrivateLayout';
import PublicLayout from './Components/Layout/PublicLayout';
import { UploadAsset } from './Components/Upload/UploadAsset';
import Asset from './Pages/Asset';
import Categories from './Pages/Categories';
import Login from './Pages/Login';
import Notification from './Pages/Notification';
import { Subscription } from './Pages/Subscription';
import { setRfreshToken, setUser } from './features/authentication/AuthenticationSlice';
import './index.css';

function App() {
  const { refreshTokenData } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // check if token is expired
  useEffect(() => {
    if (refreshTokenData) {
      dispatch(
        setUser({
          userId: refreshTokenData?.userId,
          accessToken: refreshTokenData?.accessToken,
          idToken: refreshTokenData?.idToken,
          refreshToken: refreshTokenData?.refreshToken,
        })
      );
      dispatch(setRfreshToken(null));
      // console.log('set new token::::::::::::');
      navigate(0);
    }
  }, [refreshTokenData, navigate, dispatch]);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/assets');
    }
  }, []);

  return (
    <div className="App">
      <Toaster position="top-right" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<PrivateLayout />}>
          <Route path="/categories" index element={<Categories />} />
          <Route path="/assets" index element={<Asset />} />
          <Route path="/upload" index element={<UploadAsset />} />
          <Route path="/notification" index element={<Notification />} />
          <Route path="/subscriptions" index element={<Subscription />} />
        </Route>

        <Route path="/login" element={<PublicLayout />}>
          <Route exact index element={<Login />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
