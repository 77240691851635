import { createSlice } from '@reduxjs/toolkit';

const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    userId: null,
    accessToken: null,
    idToken: null,
    refreshToken: null,
    refreshTokenData: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.userId = action.payload.userId;
      state.accessToken = action.payload.accessToken;
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
    },
    setRfreshToken: (state, action) => {
      state.refreshTokenData = action.payload;
    },
    logoutUser: (state) => {
      state.userId = null;
      state.accessToken = null;
      state.idToken = null;
      state.refreshToken = null;
    },
  },
});

export const { setUser, logoutUser, setRfreshToken } = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;
