import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { Sidebar } from '../Common/Sidebar';

export const PrivateLayout = () => {
  const authData = useSelector((state) => state.authentication);
  const isUserLoggedIn = !!authData?.accessToken;

  return isUserLoggedIn ? (
    <div className="bg-cover h-auto xl:fixed xl:h-full w-full bg-no-repeat bg-[url('/public/main-bg.svg')]">
      {/* <Navbar /> */}
      <Sidebar />
      <div className="p-4 sm:ml-64">
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/login" replace />
  );
};
