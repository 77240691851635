/* eslint-disable */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from 'axios';
import { Breadcrumb, Dropdown } from "flowbite-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import * as variables from '../../config/variable';
import { apiSlice } from "../../features/api/apiSlice";
import {
  useDeleteAssetMutation,
  useGetAllAssetsOrderedListQuery,
  useUpdateStatusAssetsMutation
} from "../../features/assets/assetsAPI";
import { setAssetDetails } from "../../features/assets/assetsSlice";
import { setCategories } from '../../features/assets/categoriesSlice';
import { setSeriesDetails, setSeriesWithEpisodeDetails } from "../../features/assets/seriesSlice";
import ErrorAlert from "../Message/ErrorAlert";
import Image from "./components/Image";

const clickableStyle = {
  color: '#007379',
  cursor: 'pointer',
  transition: 'color 0.3s, text-decoration 0.3s',
};
const AssetsListOrderable = ({ setCloseDetails }) => {
  const navigate = useNavigate();
  const { categories = [] } = useSelector((state) => state.categories);
  // console.log("AssetsListOrderable categories: ", categories);
  const [isCategoryError, setIsCategoryError] = useState(false);
  const [catError, setCatError] = useState("");
  const location = useLocation();
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const categoryId = getQueryParam("categoryId");
  //const [categories, setCategories] = useState([]);
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageData, setImageData] = useState(null);
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.authentication);
  const {
    data: assetList,
    error,
    isError,
    isLoading,
    isFetching
  } = useGetAllAssetsOrderedListQuery(
    { allasset: "true", },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      pollingInterval: 1200000 // 20 minutes interval
    }
  );
  // console.log("assetList: ",assetList);
  // console.log("isLoading:", isLoading);
  // console.log("isFetching:", isFetching);

  const [updateStatusAssets, { isSuccess, error: statusError }] = useUpdateStatusAssetsMutation();
  const [deleteAsset, { isSuccess: isAssetDelete, error: assetDeleteError }] =
    useDeleteAssetMutation();
    const isSeriesInTags = (tagData = '') => {
      let categoriesNames = [];
      if (Array.isArray(tagData)) {
        categoriesNames = tagData;
      } else {
        categoriesNames = tagData ? tagData.split(',') : [];
      }
      // console.log('isSeriesInTags categoriesNames', categoriesNames);
      return categoriesNames.map((item) => String(item).toLowerCase()).includes('series');
    };
  const handelDetails = useCallback(
    ({
      title,
      type,
      description,
      cover,
      hidden,
      AssetId,
      content,
      appleStoreId,
      playStoreId,
      assetDuration,
      isPremium,
      tag,
      seriesName,
      seriesDescription,
      seriesCover,
      episodeNo,
      order,
      isSeries
    }) => {
      dispatch(
        setAssetDetails({
          title,
          type,
          description,
          cover: cover,
          AssetId,
          imageInfo: cover,
          content,
          hidden,
          appleStoreId,
          playStoreId,
          assetDuration,
          isPremium,
          tag,
          seriesName,
          seriesDescription,
          seriesCover,
          episodeNo,
          order,
          isSeries  
        })
      );
    },
    [dispatch, imageData]
  );

  const getCategoryNamesByIds = (categoryList = [], idsString = "") => {
    let idsArray = [];
    if (Array.isArray(idsString)) {
      idsArray = idsString;
    } else {
      idsArray = idsString ? idsString.split(',') : [];
    } 
    const categoryNames = idsArray.map((id) => {
      const category = categoryList.find((cat) => cat.id === id);
      return category ? String(category.category) : null; // Return category name if found, otherwise null
    });
    // console.log('categoryNames: ', categoryNames);
    const data = categoryNames.filter(Boolean); // Filter out null values
    return data.length > 0 ? data : idsArray;
  };
  const getCategoryIdsByName = (categoryList = [], tag = "") => {
    // console.log("getCategoryNamesByIds idsString: ",idsString);
    // console.log("categoryList: ",categoryList);
    const tagsArray = tag ? tag.split(',') : []; // Split the string into an array of IDs
    // console.log('idsArray: ', idsArray);
    const categoryIds = tagsArray.map((name) => {
      const category = categoryList.find((cat) => String(cat.category).toLowerCase() === String(name).toLowerCase());
      return category ? String(category.id) : null; // Return category name if found, otherwise null
    });
    // console.log('categoryNames: ', categoryNames);
    const data = categoryIds.filter(Boolean); // Filter out null values
    return data.length > 0 ? data : [];
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setIsCategoryError(false);
      setCatError("");
      return axios
        .get(`${variables.apiUrl}/admin/category/list`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          //setLoading(false);
          dispatch(setCategories(response?.data?.categories || []));
          //setCategories(response?.data?.categories);
        })
        .catch((error) => {
          setIsCategoryError(true);
          setCatError("Error fetching category list");
          toast.error('Error fetching category list');
          //setLoading(false);
        });
    };
    if (accessToken && gridApi && categories.length === 0) {
      fetchData();
    }
  }, [accessToken, gridApi, categories]);

  useEffect(() => {
    if(categories.length > 0 ){
      setColumnDefs([
        {
          headerName: "Asset Title",
          field: "title",
          filter: false,
          floatingFilter: false,
          cellRenderer: ImageCellRenderer,
          flex: 2,
          minWidth: 250,
          rowDrag: true
        },
        {
          headerName: "Category",
          field: "tag",
          cellRenderer: TypeCellRenderer,
          cellRendererParams: {
            categories: categories
          },
        },
        {
          headerName: "Status",
          field: "hidden",
          cellRenderer: StatusCellRenderer,
          sortable: false,
          resizable: false,
        },
        {
          headerName: "Actions",
          cellRenderer: ActionCellRenderer,
          sortable: false,
          resizable: false,
        }
      ]);
     
      if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    }

  }, [categories])

  const handelOnUpdate = useCallback(
    ({ assetId, value }) => {
      updateStatusAssets({ assetId, value });
    },
    [updateStatusAssets]
  );

  const deleteHandler = useCallback(
    (assetId) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          deleteAsset(assetId);
        }
      });
    },
    [deleteAsset]
  );

  const updateSeriesHandler = useCallback(
    ({ assetId, seriesName, seriesDescription, seriesCover }) => {
      dispatch(
        setSeriesDetails({
          assetId,
          seriesName,
          seriesDescription,
          seriesCover
        })
      );
    },
    [dispatch]
  );

  const seriesWithEpisodeHandler = useCallback(
    ({ assetId, seriesName, seriesDescription, seriesCover }) => {
      dispatch(
        setSeriesWithEpisodeDetails({
          assetId,
          seriesName,
          seriesDescription,
          seriesCover
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (isSuccess) {
      setCloseDetails(true);
      setLoading(true);
      dispatch(apiSlice.util.invalidateTags(['getAllAssetsOrderedList']));
      toast.success("Asset status updated successfully");
    }
    if (statusError) {
      toast.error("Asset status update failed");
    }
  }, [isSuccess, setCloseDetails, statusError]);

  useEffect(() => {
    if (isAssetDelete) {
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: "Asset was successfully deleted",
        showConfirmButton: false,
        timer: 1500
      });
    }
    if (assetDeleteError) {
      toast.error("Error deleting asset");
    }
  }, [assetDeleteError, isAssetDelete]);

  const ImageCellRenderer = useCallback(
    (params) => (
      <div className="flex items-center">
        <Image imageData={imageData} setImageData={setImageData} row={params.data} />
        <h2 className="ml-3 text-soc-light-on-surface-variant font-medium text-sm">
          {params.value}
        </h2>
      </div>
    ),
    [imageData]
  );

  const TypeCellRenderer = (params) => {
    // console.log("params.data.seriesName: ",params.data.seriesName);
    const categoryNames = params.value? params.value.split(",") : [];
    return (<div>
      <h2 className="ml-1 capitalize font-medium text-sm">
        <span>
          {categoryNames.map((item) => String(item).toUpperCase()).join(',')} {params.data.episodeNo && (
            <>
            (
              {/* <span style={clickableStyle}>
                {params.data.seriesName}
              </span> */}
              {`EP ${params.data.episodeNo}`})
            </>
          )}
        </span>
      </h2>
    </div>);
  };

  const StatusCellRenderer = ({ data }) => {
    return (
      <div>
        {data.hidden === false ? (
          <span className="bg-soc-light-surface-secondary-container text-soc-light-surface-on-secondary-container px-2 py-1 font-semibold rounded-lg text-center">
            Published
          </span>
        ) : (
          <span className="bg-soc-light-surface-error-container text-soc-light-surface-on-error-container px-2 py-1 font-semibold rounded-lg text-center">
            Unpublished
          </span>
        )}
      </div>
    );
  };

  const ActionCellRenderer = (params) => {
    // console.log("ActionCellRenderer",params.data);
    return (
    
    <div className="flex">
      <button
        className="inline-flex items-center p-1 rounded-full hover:bg-[#49454f24] transition-all"
        type="button"
        onClick={() => handelDetails(params?.data)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.06 3.79008L20.41 5.14008C21.2 5.92008 21.2 7.19008 20.41 7.97008L7.18 21.2001H3V17.0201L13.4 6.61008L16.23 3.79008C17.01 3.01008 18.28 3.01008 19.06 3.79008ZM5 19.2001L6.41 19.2601L16.23 9.43008L14.82 8.02008L5 17.8401V19.2001Z"
            fill="#3F4949"
          />
        </svg>
      </button>

      <div className="p-1 rounded-full hover:bg-[#49454f24] transition-all">
        <Dropdown
          arrowIcon={false}
          size="sm"
          color="#3F4949"
          className="flowbite-dropdown" // Add your custom class
          renderTrigger={() => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 8.2002C13.1 8.2002 14 7.3002 14 6.2002C14 5.1002 13.1 4.2002 12 4.2002C10.9 4.2002 10 5.1002 10 6.2002C10 7.3002 10.9 8.2002 12 8.2002ZM12 10.2002C10.9 10.2002 10 11.1002 10 12.2002C10 13.3002 10.9 14.2002 12 14.2002C13.1 14.2002 14 13.3002 14 12.2002C14 11.1002 13.1 10.2002 12 10.2002ZM10 18.2002C10 17.1002 10.9 16.2002 12 16.2002C13.1 16.2002 14 17.1002 14 18.2002C14 19.3002 13.1 20.2002 12 20.2002C10.9 20.2002 10 19.3002 10 18.2002Z"
                fill="#3F4949"
              />
            </svg>
          )}
        >
          {(String(params?.data?.tag).toLowerCase() === "series" && params?.data?.seriesName && params?.data?.episodeNo) && (
            <Dropdown.Item onClick={() => updateSeriesHandler(params.data)}>
              Update Series
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() =>
              handelOnUpdate({
                assetId: params.data.AssetId,
                value: params.data.hidden === false ? "true" : "false"
              })
            }
          >
            {params.data.hidden === true ? "Publish" : "Unpublish"}
          </Dropdown.Item>
          <Dropdown.Item  onClick={() => deleteHandler(params.data.AssetId)}>
            Delete
          </Dropdown.Item>
        </Dropdown>
      </div>
    </div>
    );
  //);
  }

  const [columnDefs, setColumnDefs] = useState([]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 150,
      sortable: false,
      resizable: false,
      floatingFilter: false,
      filter: false,
      rowDrag: false,
      wrapText: true,
      autoHeight: true,
      suppressMovable: false,
      suppressHorizontalScroll: true,
      suppressCellSelection: true,
      cellStyle: { display: 'flex', alignItems: 'center',  padding: "10px"}
    }),
    []
  );
  const rowSelection = "single";
  const gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true
    },
    onCellClicked: (event) => {
      const categoryNames = event.data.tag ? event.data.tag.split(",") : [];
      if (event.colDef.field && event.colDef.field === "tag" && String(event.data.tag).toLowerCase() === "series" && event.data.seriesName && event.data.episodeNo) {
        seriesWithEpisodeHandler(event.data);
      } else if (
        event.colDef.field === "title" ||
        event.colDef.field === "tag" ||
        event.colDef.field === "hidden"
      ) {
        handelDetails(event.data);
      }
      return;
    },
    columnDefs: columnDefs,
    rowData
  };
  const onGridReady = (params) => {
    // console.log("params.api: ",params.api);
    setGridApi(params.api);
    gridRef.current.api.sizeColumnsToFit();
  };

  useEffect(() => {
    if (gridApi && assetList && categories.length > 0) {
      const assetsData = assetList?.data?.map(
        ({
          title,
          type,
          description,
          hidden,
          cover,
          AssetId,
          content,
          appleStoreId,
          playStoreId,
          assetDuration,
          tag,
          seriesName,
          isPremium,
          seriesDescription,
          seriesCover,
          episodeNo,
          categories,
          order,
          isSeries
        }) => {
          const renderContent = {
            id: AssetId,
            assetTitle: title,
            assetType: type || "None",
            status: hidden === "false" ? "false" : "true",
            title,
            type,
            description,
            hidden,
            cover,
            AssetId,
            content,
            appleStoreId,
            playStoreId,
            assetDuration,
            isPremium,
            tag,
            seriesName,
            seriesDescription,
            seriesCover,
            episodeNo,
            categories,
            order,
            isSeries
          };
          return renderContent;
        }
      ).filter((item) => checkCategoryIdExistsInTags(item.tag, categoryId)).sort((a, b) => Number(a.order) - Number(b.order));
      // console.log("assetsData: ", assetsData);
      setRowData(assetsData);
      setLoading(false);
    }
  }, [gridApi, assetList, categories]);

  const getCategoryNameById = (categoryId = '') => {
    const data = categories.find((category) => category.id === categoryId);
    return data ? String(data.category).toLowerCase() : '';
  };

  const checkCategoryIdExistsInTags = (tag = "", categoryId) => {
    // console.log("tag: ",tag);
    // console.log("categoryId: ",categoryId);
    const tagsIds = tag ? getCategoryIdsByName(categories, tag) : [];
    return tagsIds.includes(categoryId) === true ? true : (String(tag).toLowerCase() === getCategoryNameById(categoryId));
  }

  const onRowDragEnd = async (event) => {
    try {
      setLoading(true);
      const sortedRowData = [];
      const allRows = event.api.getDisplayedRowCount();
      for (let i = 0; i < allRows; i++) {
        const rowNode = event.api.getDisplayedRowAtIndex(i);
        if (rowNode.data && typeof rowNode.data === 'object') {
          sortedRowData.push(rowNode.data.id);
        }
      }
      //   console.log("updatedRowIds:", updatedRowIds);
      //   console.log("New row order:", updatedRowData);
      setLoading(true);
      await axios.post(`${variables.apiUrl}/admin/content-order/${categoryId}`, {order: [...sortedRowData]}, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      dispatch(apiSlice.util.invalidateTags(['getAllAssetsOrderedList']));
    } catch (error) {
      toast.error("Error updating assets order");
    } finally {
      toast.success("Assets order updated successfully");
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsCategoryError(false);
      setCatError("");
    }
  }, []);
  function toTitleCase(str = "") {
    return str
        .toLowerCase()      // Convert the entire string to lowercase
        .split(' ')         // Split the string into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' ');         // Join the words back together with spaces
 }
  const renderSeletedCategory = () => {
    const categoryName = getCategoryNameById(categoryId);
    return (
      <Breadcrumb aria-label="Default breadcrumb example">
        <Breadcrumb.Item href="#" onClick={() => navigate("/categories")}>
          Categories
        </Breadcrumb.Item>
        <Breadcrumb.Item href="#" onClick={() => navigate("/categories")}>{toTitleCase(categoryName)}</Breadcrumb.Item>
        <Breadcrumb.Item href="#">Assets</Breadcrumb.Item>
      </Breadcrumb>
  )
  }
  return (
    <>
      {!isError && isCategoryError && <ErrorAlert message={error} />}
      {!isCategoryError && isError && <ErrorAlert message={error?.message || error?.data?.error} />}
      <div className="my-6">
        {!loading && categoryId && categories.length > 0 ? renderSeletedCategory() : ""}
          
        <div
          className="ag-theme-quartz"
          style={{ height: 500, marginTop: "10px", overflowY: "auto" }}
        >
          <AgGridReact
            style={{ width: "100%", height: "100%" }}
            ref={gridRef}
            onGridReady={onGridReady}
            overlayNoRowsTemplate="<span>There are no assets to display for this category.</span>"
            overlayLoadingTemplate='<span className="ag-overlay-loading-center">Please wait while your assets are loading...</span>'
            loading={loading || isLoading || isFetching }
            //loading={loading}
            columnDefs={columnDefs}
            rowData={rowData}
            // domLayout="autoHeight"
            gridOptions={gridOptions}
            defaultColDef={defaultColDef}
            rowSelection={rowSelection}
            rowBuffer={30}
            rowDragManaged
            onRowDragEnd={onRowDragEnd} // Attach the row drag end event
          />
        </div>
      </div>
    </>
  );
};

export default AssetsListOrderable;
