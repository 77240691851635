import { CognitoRefreshToken, CognitoUser } from 'amazon-cognito-identity-js';
import UserPoll from '../config/cognitoConfig';

const getRefreshToken = async (userId, refreshToken) => {
  const userData = {
    Username: userId,
    Pool: UserPoll,
  };
  const cognitoUser = new CognitoUser(userData);

  const rfToken = new CognitoRefreshToken({ RefreshToken: refreshToken });

  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(rfToken, (error, session) => {
      if (error) {
        // console.log({ error });
        reject(error);
      } else {
        // console.log('generate new refresh token:::', { session });
        resolve(session);
      }
    });
  });
};

export default getRefreshToken;
