/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
import axios from 'axios';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import 'filepond/dist/filepond.min.css';
import { Spinner } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { toast } from 'react-hot-toast';
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch, useSelector } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import * as variables from '../../config/variable';
import { apiSlice } from '../../features/api/apiSlice';
import { useGetAllAssetsQuery } from '../../features/assets/assetsAPI';
import { setCategories } from '../../features/assets/categoriesSlice';
import getAssetDuration from '../../uitls/getAssetDuration';
import AudioPlayer2 from '../AudioPlayer/AudioPlayer2';
import ErrorAlert from '../Message/ErrorAlert';
import MultiSelectDropdown from './Components/MultiSelectDropdown';

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview
);

export const UploadAsset = ({ isEditable = false, handelCancel, setCloseDetails, tagId }) => {
  const { categories = [] } = useSelector((state) => state.categories);
  // console.log('UploadAsset categories: ', categories);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [isSeries, setIsSeries] = useState(false);
  const [seriesList, setSeriesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState([]);
  const [oldTag, setOldTag] = useState([]);
  const isOldTagSet = useRef(false);
  const { data: listData } = useGetAllAssetsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    pollingInterval: 1200000,
  });

  const hasFetchedCategories = useRef(false);

  useEffect(() => {
    return () => {
      hasFetchedCategories.current = false;
    };
  }, []);

  const [episodNo, setEpisodeNumber] = useState('');
  // const [categories, setCategories] = useState([]);
  const getCategoryNamesByIds = (idsString = '') => {
    // console.log('getCategoryNamesByIds idsString: ', idsString);
    let idsArray = [];
    if (idsString && Array.isArray(idsString)) {
      idsArray = idsString;
    } else {
      idsArray = idsString ? idsString.split(',') : [];
    }
    // console.log('idsArray: ', idsArray);
    const categoryNames = idsArray.map((id) => {
      const category = categories.find((cat) => cat.id === id);
      return category ? String(category.category) : null; // Return category name if found, otherwise null
    });
    // console.log('categoryNames: ', categoryNames);
    const data = categoryNames.filter(Boolean); // Filter out null values
    return data.length > 0 ? data : idsArray;
  };

  const getCategoryIdsByName = (categoryList = [], tags = '') => {
    const tagsArray = tags ? tags.split(',') : []; // Split the string into an array of IDs
    // console.log('idsArray: ', idsArray);
    const categoryIds = tagsArray.map((name) => {
      const category = categoryList.find(
        (cat) => String(cat.category).toLowerCase() === String(name).toLowerCase()
      );
      return category ? String(category.id) : null; // Return category id if found, otherwise null
    });
    // console.log('categoryNames: ', categoryNames);
    const data = categoryIds.filter(Boolean); // Filter out null values
    return data.length > 0 ? data : [];
  };

  const isSeriesInTagsByTagIds = (data) => {
    const categoriesNames = getCategoryNamesByIds(data) || [];
    // console.log('isSeriesInTagsByTagIds:', data, categoriesNames);
    return categoriesNames.map((item) => String(item).toLowerCase()).includes('series');
  };
  const isSeriesInTags = (tagData = '') => {
    let categoriesNames = [];
    if (Array.isArray(tagData)) {
      categoriesNames = tagData;
    } else {
      categoriesNames = tagData ? tagData.split(',') : [];
    }
    // console.log('isSeriesInTags categoriesNames', tagData, categoriesNames);
    return categoriesNames.map((item) => String(item).toLowerCase()).includes('series');
  };

  const getIdByCategoryName = (categoryName) => {
    const data = categories.find(
      (category) => String(category.category).toLowerCase() === String(categoryName).toLowerCase()
    );
    return data ? data.id : null;
  };

  // useEffect(() => {
  //   if (categories.length > 0 && !isEditable) {
  //     const categoryId = tagId || getIdByCategoryName('New');
  //     if (categoryId) {
  //       setTag([categoryId]);
  //     }
  //   }
  // }, [categories]);

  const getLastEpisodeNumber = (Data) => {
    const filteredSeries = Data.data.Items.filter(
      (item) =>
        String(item.tag).toLowerCase() === 'series' ||
        Boolean(item.tag) === true ||
        isSeriesInTags(item.tag)
    );
    const lastEpisodeNumber = filteredSeries.reduce((maxEpisodeNumber, series) => {
      const episodeNumber = parseInt(series.episodNo, 10);
      if (!Number.isNaN(episodeNumber) && episodeNumber > maxEpisodeNumber) {
        return episodeNumber;
      }
      return maxEpisodeNumber;
    }, 0);
    const incrementedEpisodeNumber = lastEpisodeNumber + 1;
    return String(incrementedEpisodeNumber); // Convert the number to a string
  };

  useEffect(() => {
    if (listData) {
      // console.log('listData:', listData);
      const filteredSeries = listData.data.Items.filter(
        (item) =>
          String(item.tag).toLowerCase() === 'series' ||
          Boolean(item.tag) === true ||
          isSeriesInTags(item.tag)
      );
      // console.log('filteredSeries:', filteredSeries);
      const seriesMap = new Map();
      filteredSeries.forEach((item) => {
        const { seriesName } = item;
        if (!seriesMap.has(seriesName)) {
          seriesMap.set(seriesName, item);
        }
      });
      const uniqueSeriesList = Array.from(seriesMap.values());
      setSeriesList(uniqueSeriesList);
    }
  }, [listData]);
  const [selectedSeries, setSelectedSeries] = useState('');
  const { assetsDetails } = useSelector((state) => state.assets);
  // console.log('assetsDetails: ', assetsDetails);
  const { accessToken } = useSelector((state) => state.authentication);
  const playerRef = React.useRef();
  // const previewCanvasRef = useRef(null);
  // const [imgSrc, setImgSrc] = useState(null);
  const [imgBlob, setImgBlob] = useState(null);
  const [content, setContent] = useState(null);
  // const [cropImage, setCropImage] = useState();
  // const [isCropping, setIsCropping] = useState(false);
  // const [completedCrop, setCompletedCrop] = useState();
  // const [aspect, setAspect] = useState(16 / 9);
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');
  const [hidden, setHidden] = useState('true');
  const [type, setType] = useState('audio');
  const [msgText, setMsgText] = useState(null);
  const [appleStoreId, setAppleStoreId] = useState('');
  const [playStoreId, setPlayStoreId] = useState('');
  const [assetDuration, setAssetDuration] = useState('00:00');
  // const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [imageInfo, setImageInfo] = useState({
    name: '',
    type: '',
  });
  const [contentInfo, setContentInfo] = useState({
    name: '',
    type: '',
  });

  const [seriesName, setSeriesName] = useState('');
  const [seriesDescription, setSeriesDescription] = useState('');
  const [seriesCover, setSeriesCover] = useState(null);
  const [seriesCoverImageInfo, setSeriesCoverImageInfo] = useState({
    name: '',
    type: '',
  });
  const [isPremium, setIsPremium] = useState(false);
  // const getAudioDuration = (file) => {
  //   const fileReader = new FileReader();
  //   fileReader.readAsArrayBuffer(file);

  //   fileReader.onload = () => {
  //     const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  //     audioContext.decodeAudioData(fileReader.result, (buffer) => {
  //       const { duration } = buffer;
  //       console.log(`Audio duration: ${duration} seconds`);
  //     });
  //   };
  // };

  // useEffect(() => {
  //   console.log(content && content[0]);
  //   if (content && content[0]) {
  //     getAudioDuration(content);
  //   }
  // }, [content]);
  const handleSelectSeries = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === '') {
      setSeriesDescription('');
      setSeriesName('');
      setSeriesCover('');
      setEpisodeNumber('');
    } else if (selectedValue !== 'new') {
      const series = JSON.parse(selectedValue);
      setSeriesDescription(series.seriesDescription);
      setSeriesName(series.seriesName);
      setSeriesCover(series.seriesCover);
      const lastEpisodeNumber = getLastEpisodeNumber(listData);
      setEpisodeNumber(lastEpisodeNumber);
    } else {
      setSeriesDescription('');
      setSeriesName('');
      setSeriesCover('');
      setEpisodeNumber('');
    }
    setSelectedSeries(selectedValue);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (content?.[0]) {
      setContentInfo({ name: content?.[0]?.filename, type: content?.[0]?.fileType });
    }
  }, [content]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDetailsChange = (event) => {
    setDetails(event.target.value);
  };

  const handleTypeChange = (event) => {
    if (event.target.value !== 'Select...') {
      setType(event.target.value);
    } else {
      setType('');
    }
  };

  // const handleTagChange = (event) => {
  //   setTag(event.target.value);
  // };
  const handleSeriesNameChange = (event) => {
    setSeriesName(event.target.value);
  };
  const handleEpisodeNumberChange = (event) => {
    setEpisodeNumber(event.target.value);
  };
  const handleSeriesDescriptionChange = (event) => {
    setSeriesDescription(event.target.value);
  };

  const handleAppleStoreId = (event) => {
    setAppleStoreId(event.target.value);
  };

  const handlePlayStoreId = (event) => {
    setPlayStoreId(event.target.value);
  };
  const handleIsPremiumChange = (event) => {
    // const newValue = event.target.value === 'true';
    setIsPremium(event.target.value);
  };

  const preSigndUrl = async () => {
    const url = `${variables.apiUrl}/admin/asset/upload/url`;
    const seriesCoverData =
      seriesCoverImageInfo.name !== '' && seriesCoverImageInfo.type !== ''
        ? {
            name: seriesCoverImageInfo.name,
            type: seriesCoverImageInfo.type,
          }
        : undefined;

    const data = {
      cover: {
        name: imageInfo.name,
        type: imageInfo.type,
      },

      content: {
        name: contentInfo.name,
        type: contentInfo.type,
      },
      ...(seriesCoverData && { seriesCover: seriesCoverData }),
    };
    const response = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  };

  const coverPreSigndUrl = async () => {
    const url = `${variables.apiUrl}/admin/asset/upload/url`;
    const data = {
      cover: {
        name: imageInfo.name,
        type: imageInfo.type,
      },
    };
    const response = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  };

  const contentPreSigndUrl = async () => {
    const url = `${variables.apiUrl}/admin/asset/upload/url`;
    const data = {
      content: {
        name: contentInfo.name,
        type: contentInfo.type,
      },
    };
    const response = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  };

  const postAssets = async (presigndUrlData, blob) => {
    try {
      // console.log('presigndUrlData.coverUrl: ', presigndUrlData.coverUrl);
      const s3Url = presigndUrlData.coverUrl.split('/covers')[0];
      // console.log('s3Url: ', s3Url);
      const saveData = {
        title,
        description: details,
        type,
        hidden: hidden.toString(),
        appleStoreId,
        playStoreId,
        assetDuration,
        assetId: presigndUrlData.assetId,
        cover: `${s3Url}/${presigndUrlData.coverName}`,
        content: `${s3Url}/${presigndUrlData.contentName}`,
        tag,
        isPremium,
        // isSeries,
        categoryId: tagId,
      };
      // if (tag === 'series') {
      if (
        seriesName !== '' &&
        seriesDescription !== '' &&
        episodNo !== '' &&
        presigndUrlData.seriesCoverName
      ) {
        saveData.seriesName = seriesName;
        saveData.seriesDescription = seriesDescription;
        saveData.episodNo = episodNo;
        saveData.seriesCover = `${s3Url}/${presigndUrlData.seriesCoverName}`;
        if (presigndUrlData.seriesCoverUrl) {
          await axios.put(presigndUrlData.seriesCoverUrl, seriesCover, {
            headers: {
              'Content-Type': seriesCoverImageInfo.type,
            },
          });
        }
      }
      // console.log({ saveData });

      await axios
        .put(presigndUrlData.contentUrl, content[0]?.file, {
          headers: {
            'Content-Type': contentInfo.type,
          },
        })
        .catch((e) => {
          console.log(e);
        });

      await axios
        .put(presigndUrlData.coverUrl, blob, {
          headers: {
            'Content-Type': imageInfo.type,
          },
        })
        .catch((e) => {
          console.log(e);
        });

      const response = await axios.post(`${variables.apiUrl}/admin/asset/upload`, saveData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response;
    } catch (error) {
      console.log(error);
      setMsgText(error?.message);
      return null;
    }
  };
  const updateAsset = async (updateData, blob) => {
    try {
      const saveData = {
        title,
        description: details,
        type,
        hidden,
        appleStoreId,
        playStoreId,
        assetDuration,
        isPremium,
        tag,
        oldTag,
        categoryId: tagId,
        ...(seriesName && { seriesName }),
        ...(seriesDescription && { seriesDescription }),
        ...(episodNo && { episodNo }),
      };
      if (updateData?.contentName) {
        await axios.put(updateData.contentUrl, content[0]?.file, {
          headers: {
            'Content-Type': contentInfo.type,
          },
        });
        saveData.content = updateData.contentName;
      }

      if (updateData?.coverName) {
        await axios.put(updateData.coverUrl, blob, {
          headers: {
            'Content-Type': imageInfo.type,
          },
        });
        saveData.cover = updateData.coverName;
      }

      const response = await axios.put(
        `${variables.apiUrl}/admin/asset/${assetsDetails?.AssetId}`,
        saveData,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return response;
    } catch (error) {
      // console.log('update faild', error);
      setMsgText(error?.message);
      return null;
    }
  };

  const handleSubmit = async (event) => {
    // setIsUploadSuccess(false);
    try {
      event.preventDefault();
      setMsgText(null);
      setIsPosting(true);
      // if (
      //   !isEditable &&
      //   (String(tag).toLowerCase() === 'series' ||
      //     isSeries === true ||
      //     isSeriesInTagsByTagIds(tag)) &&
      //   (!seriesName || !seriesDescription || !episodNo || !seriesCover)
      // ) {
      //   throw new Error('Series name, description, Cover and episode number must be provided');
      // }
      if (tag && tag.length === 0) {
        throw new Error('Category must be selected');
      }

      if (!title || !details || !type) {
        throw new Error('Title, Details and Type must be provided');
      }

      if (!isEditable && !content) {
        throw new Error('Asset must be selected');
      }
      if (!isEditable && !imgBlob) {
        throw new Error('Cover Art must be selected');
      }
      // const type = content?.[0]?.fileType?.toLowerCase()?.includes('audio') ? 'audio' : 'video';
      if (!isEditable) {
        // console.log(content, data);

        const preSgindResponse = await preSigndUrl();
        // console.log({ preSgindResponse });
        const response = await postAssets(preSgindResponse?.data, imgBlob);

        if (response?.data?.assetId) {
          // setIsUploadSuccess(true);
          setCloseDetails(true);
          toast.success('Asset uploaded successfully');
          dispatch(apiSlice.util.invalidateTags(['getAssetsList']));
          dispatch(apiSlice.util.invalidateTags(['getAllAssetsOrderedList']));
        }

        setIsPosting(false);
      } else {
        const updatedData = {};
        if (imgBlob) {
          const preSgindResponse = await coverPreSigndUrl();
          const response = preSgindResponse?.data;
          if (response) {
            const s3Url = response?.coverUrl.split('/covers')[0];
            updatedData.coverName = `${s3Url}/${response?.coverName}`;
            updatedData.coverUrl = response?.coverUrl;
          }
        }
        if (content?.length > 0) {
          const preSgindResponse = await contentPreSigndUrl();
          const response = preSgindResponse?.data;
          if (response) {
            const s3Url = response?.contentUrl.split('/assets')[0];
            updatedData.contentName = `${s3Url}/${response?.contentName}`;
            updatedData.contentUrl = response?.contentUrl;
          }
        }

        if (updatedData?.coverName) {
          const response = await updateAsset(updatedData, imgBlob);

          if (response?.data) {
            // setIsUploadSuccess(true);
            setCloseDetails(true);
            toast.success('Asset updated successfully');
            // setMsgText('Asset updated successfully');
            dispatch(apiSlice.util.invalidateTags(['getAssetsList']));
            dispatch(apiSlice.util.invalidateTags(['getAllAssetsOrderedList']));
          }
          setIsPosting(false);
        } else {
          const response = await updateAsset(updatedData);

          if (response?.data) {
            // setIsUploadSuccess(true);
            // setMsgText('Asset updated successfully');
            setCloseDetails(true);
            toast.success('Asset updated successfully');
            dispatch(apiSlice.util.invalidateTags(['getAssetsList']));
            dispatch(apiSlice.util.invalidateTags(['getAllAssetsOrderedList']));
          }
          setIsPosting(false);
        }
      }
    } catch (error) {
      // console.log(error);
      // setIsUploadSuccess(false);
      setMsgText(error?.message);
      setIsPosting(false);
    }
  };

  const onChangeImageFile = (files) => {
    if (files?.length > 0) {
      setImageInfo({ name: files[0].file?.name, type: files[0].file?.type });
      // setCropImage(undefined);
      //  setImgSrc(URL.createObjectURL(files[0].file));
      setImgBlob(files[0].file);
      //  setIsCropping(true);
    }
  };

  const onChangeSeriesCoverFile = (files) => {
    if (files?.length > 0) {
      setSeriesCoverImageInfo({ name: files[0].file?.name, type: files[0].file?.type });
      setSeriesCover(files[0].file);
    }
  };

  const imageOnRemove = () => {
    setImgBlob(null);
  };

  const contentOnRemove = () => {
    setContent(null);
  };

  const seriesCoverOnRemove = () => {
    setSeriesCover(null);
  };
  // console.log({ isPosting });
  useEffect(() => {
    return () => {
      setIsError(false);
      setError('');
    };
  }, []);
  useEffect(() => {
    if (isEditable && Object.keys(assetsDetails).length > 0 && categories.length > 0) {
      setTitle(assetsDetails?.title || '');
      setDetails(assetsDetails?.description || '');
      setType(assetsDetails?.type || '');
      setHidden(assetsDetails?.hidden === false ? 'false' : 'true' || 'true');
      setAppleStoreId(assetsDetails?.appleStoreId || '');
      setPlayStoreId(assetsDetails?.playStoreId || '');
      setAssetDuration(assetsDetails?.assetDuration || '00:00');
      setIsPremium(assetsDetails?.isPremium || false);
      // setIsSeries(Boolean(assetsDetails?.isSeries) || false);
      // setTag(assetsDetails?.tag);
      const assetsDetailsTagsId = assetsDetails?.tag
        ? getCategoryIdsByName(categories, assetsDetails?.tag)
        : [];
      if (assetsDetailsTagsId && assetsDetailsTagsId.length > 0) {
        setTag((prevTags) => [...assetsDetailsTagsId]);
        if (isOldTagSet.current === false) {
          setOldTag((prevTags) => [...assetsDetailsTagsId]);
          isOldTagSet.current = true;
        }
      }
      setEpisodeNumber(assetsDetails?.episodNo || '');
      setSeriesDescription(assetsDetails?.seriesDescription || '');
      setSeriesName(assetsDetails?.seriesName || '');
    } else if (!isEditable && categories.length > 0) {
      setTitle('');
      setDetails('');
      setType('audio');
      setHidden('true');
      setAppleStoreId('');
      setPlayStoreId('');
      setAssetDuration('00:00');
      // setTag('new');
      // const categoryId = tagId || getIdByCategoryName('New');
      const categoryId = tagId;
      if (categoryId) {
        setTag([categoryId]);
      }
      setSeriesName('');
      setSeriesDescription('');
      setEpisodeNumber('');
      setIsPremium(false);
    }
  }, [
    assetsDetails?.appleStoreId,
    assetsDetails?.assetDuration,
    assetsDetails?.description,
    assetsDetails?.hidden,
    assetsDetails?.playStoreId,
    assetsDetails?.title,
    assetsDetails?.type,
    isEditable,
    assetsDetails?.isPremium,
    assetsDetails?.tag,
    assetsDetails?.episodNo,
    assetsDetails?.seriesName,
    assetsDetails?.seriesDescription,
    categories,
    // assetsDetails?.isSeries,
  ]);

  // get content

  // console.log(assetsDetails);
  // console.log(assetDuration);

  const [audioData, setAudioData] = useState(null);
  const [audioDataLoading, setAudioDataLoading] = useState(true);

  const editCoverPondRef = useRef(null);
  const editContentPondRef = useRef(null);
  const [editCover, isEditCover] = useState(false);
  const [editContent, isEditContent] = useState(false);

  useEffect(() => {
    if (assetsDetails && !imgBlob) {
      isEditCover(false);
    } else {
      isEditCover(true);
    }
  }, [assetsDetails, imgBlob]);

  useEffect(() => {
    if (assetsDetails && !content?.length > 0) {
      isEditContent(false);
    } else {
      isEditContent(true);
    }
  }, [assetsDetails, content]);

  useEffect(() => {
    if (content && content[0]?.file && editContentPondRef.current?.getFile()) {
      const callDuration = async () => {
        const duration = await getAssetDuration(editContentPondRef);
        setAssetDuration(duration);
      };
      callDuration();
    }

    if (assetsDetails?.assetDuration && !content?.length > 0) {
      setAssetDuration(assetsDetails?.assetDuration);
    }
  }, [assetsDetails, content]);

  function playVideo() {
    playerRef.current.play();
  }

  function pauseVideo() {
    playerRef.current.pause();
  }

  function toggleControls() {
    playerRef.current.controls = !playerRef.current.controls;
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setIsError(false);
      setError('');
      return axios
        .get(`${variables.apiUrl}/admin/category/list`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          setLoading(false);
          // console.log('response: ', response?.data?.categories);
          // setCategories(response?.data?.categories);
          dispatch(setCategories(response?.data?.categories || []));
        })
        .catch((error) => {
          setIsError(true);
          setError('Error fetching category list');
          toast.error('Error fetching category list');
          setLoading(false);
        });
    };
    if (accessToken && categories.length === 0 && !hasFetchedCategories.current) {
      fetchData();
      hasFetchedCategories.current = true;
    }
  }, [accessToken, categories]);

  if (loading) {
    return (
      <div className="p-6 bg-gray-100">
        <form onSubmit={handleSubmit} className="h-[88vh] overflow-y-scroll overflow-x-hidden">
          <div className="flex mb-3 items-center justify-between">
            <h4 className="text-2xl font-normal text-soc-light-on-surface-variant">
              {isEditable ? title : 'Add New Asset'}
            </h4>
            <button onClick={handelCancel} type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#3F4949"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-wrap items-center justify-center min-h-80">
            <Spinner aria-label="Extra large spinner example" size="xl" />
          </div>
        </form>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="p-6 bg-gray-100">
        <form onSubmit={handleSubmit} className="h-[88vh] overflow-y-scroll overflow-x-hidden">
          <div className="flex mb-3 items-center justify-between">
            <h4 className="text-2xl font-normal text-soc-light-on-surface-variant">
              {isEditable ? title : 'Add New Asset'}
            </h4>
            <button onClick={handelCancel} type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#3F4949"
                />
              </svg>
            </button>
          </div>
          <div>
            <ErrorAlert message={error} />
          </div>
        </form>
      </div>
    );
  }
  // console.log('oldTag: ', oldTag);
  // console.log('tag: ', tag);

  return (
    <div className="p-6 bg-gray-100">
      <form onSubmit={handleSubmit} className="h-[88vh] overflow-y-scroll overflow-x-hidden">
        <div className="flex mb-3 items-center justify-between">
          <h4 className="text-2xl font-normal text-soc-light-on-surface-variant">
            {isEditable ? title : 'Add New Asset'}
          </h4>
          <button onClick={handelCancel} type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="#3F4949"
              />
            </svg>
          </button>
        </div>
        {isEditable && (
          <>
            <div>
              <p className="mb-3 text-[#47464F] font-semibold text-medium">Cover Art</p>
              {!editCover && (
                <img
                  className="h-60 w-60 m-auto rounded-2xl object-cover"
                  src={assetsDetails.imageInfo}
                  alt="imageCover"
                />
              )}

              <div className={editCover ? 'block' : 'hidden'}>
                <FilePond
                  disabled={isPosting}
                  // labelIdle=""
                  ref={editCoverPondRef}
                  maxFiles={1}
                  imagePreviewMinHeight={400}
                  imagePreviewMaxHeight={400}
                  onremovefile={imageOnRemove}
                  onupdatefiles={onChangeImageFile}
                  allowImagePreview
                  acceptedFileTypes={['image/png', 'image/jpeg']}
                />
              </div>

              <div className="flex justify-end mt-2 px-10">
                {/* <p className="mb-3 text-[#4E5F7D] font-normal text-sm">
                  {assetsDetails?.imageInfo?.split('/covers/')[1]}
                </p> */}
                <button
                  className="flex font-semibold underline"
                  onClick={() => {
                    editCoverPondRef.current.browse();
                  }}
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.795 3.10281L15.8075 4.11531C16.4 4.70031 16.4 5.65281 15.8075 6.23781L5.885 16.1603H2.75V13.0253L10.55 5.21781L12.6725 3.10281C13.2575 2.51781 14.21 2.51781 14.795 3.10281ZM4.25 14.6603L5.3075 14.7053L12.6725 7.33281L11.615 6.27531L4.25 13.6403V14.6603Z"
                      fill="#041F21"
                    />
                  </svg>
                  Edit
                </button>
              </div>
            </div>
            <div className="border-b-2 mb-4">
              <p className="mb-3 text-[#47464F] font-semibold text-medium">Media</p>
              {!editContent && (
                <div className="text-center mt-5 px-10">
                  <AudioPlayer2 playerRef={playerRef} audioFile={assetsDetails.content} />
                </div>
              )}

              <div className={editContent ? 'block' : 'hidden'}>
                <FilePond
                  ref={editContentPondRef}
                  maxFiles={1}
                  onremovefile={contentOnRemove}
                  onupdatefiles={setContent}
                  acceptedFileTypes={[
                    'audio/mpeg',
                    'video/mp4',
                    'audio/aac',
                    'audio/x-aac',
                    'audio/mp4',
                    'audio/x-m4a',
                    'audio/wav',
                  ]}
                />
              </div>

              <div className="flex justify-end mt-2 px-10">
                {/* <p className="mb-3 text-[#4E5F7D] font-normal text-sm">
                  {assetsDetails?.content?.split('/assets/')[1]}
                </p> */}
                <button
                  className="flex font-semibold underline"
                  onClick={() => {
                    editContentPondRef.current.browse();
                  }}
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.795 3.10281L15.8075 4.11531C16.4 4.70031 16.4 5.65281 15.8075 6.23781L5.885 16.1603H2.75V13.0253L10.55 5.21781L12.6725 3.10281C13.2575 2.51781 14.21 2.51781 14.795 3.10281ZM4.25 14.6603L5.3075 14.7053L12.6725 7.33281L11.615 6.27531L4.25 13.6403V14.6603Z"
                      fill="#041F21"
                    />
                  </svg>
                  Edit
                </button>
              </div>
            </div>
          </>
        )}

        <div>
          <p className="mb-3 text-[#47464F] font-semibold text-medium">Asset</p>
          {isEditable && (
            <div className="mb-4">
              <label
                htmlFor="assetId"
                className="soc-input-label focunLevel bg-[#eef1f0] border-[#aaadac]"
              >
                <span className="focusSpan soc-input-label-span text-[#aaadac]">AssetId</span>
                <input
                  disabled
                  id="assetId"
                  type="text"
                  value={assetsDetails?.AssetId}
                  className="soc-input text-[#aaadac] "
                />
              </label>
            </div>
          )}
          <div className="mb-4">
            <label htmlFor="tag" className="soc-input-label focunLevel">
              <span className="focusSpan soc-input-label-span">Category</span>
              <MultiSelectDropdown
                options={categories}
                selectedItems={tag}
                setSelectedItems={setTag}
                isPosting={isPosting}
              />
            </label>
          </div>
          {/* <div className="mb-4">
            <label htmlFor="tag" className="soc-input-label focunLevel">
              <span className="focusSpan soc-input-label-span">is Series?</span>
              <div className="flex items-center space-x-4">
                <label className="flex items-center">
                  <Checkbox
                    // disabled={isEditable}
                    checked={isSeries === true}
                    onChange={() => setIsSeries(true)}
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="flex items-center">
                  <Checkbox
                    // disabled={isEditable}
                    checked={isSeries === false}
                    onChange={() => setIsSeries(false)}
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </label>
          </div> */}

          {isEditable && seriesName && (
            <div className="mb-4">
              <label htmlFor="series" className="soc-input-label focunLevel">
                <span className="focusSpan soc-input-label-span">Series Name</span>
                <input
                  disabled={isEditable}
                  id="seriesName"
                  type="text"
                  value={seriesName}
                  className="soc-input"
                />
              </label>
            </div>
          )}
          {isEditable && seriesDescription && (
            <div className="mb-4">
              <label htmlFor="seriesDescription" className="soc-input-label focunLevel">
                <span className="focusSpan soc-input-label-span">Series Description</span>
                <textarea
                  disabled={isEditable}
                  id="seriesDescription"
                  rows={2}
                  value={seriesDescription}
                  className="soc-input"
                />
              </label>
            </div>
          )}
          {isEditable && episodNo && (
            <div className="mb-4">
              <label htmlFor="episodNo" className="soc-input-label focunLevel">
                <span className="focusSpan soc-input-label-span">Episode Number</span>
                <input
                  disabled={isEditable}
                  id="episodNo"
                  type="text"
                  value={episodNo}
                  className="soc-input"
                />
              </label>
            </div>
          )}
          {/* {tag === 'series' && !isEditable && ( */}
          {/* {(String(tag).toLowerCase() === 'series' ||
            isSeries === true ||
            isSeriesInTagsByTagIds(tag)) &&
            !isEditable && (
              <div>
                <div className="mb-4">
                  <label htmlFor="tag" className="soc-input-label focunLevel">
                    <span className="focusSpan soc-input-label-span">Series</span>
                    <select
                      disabled={isPosting}
                      id="seriesList"
                      value={selectedSeries}
                      onChange={handleSelectSeries}
                      className="soc-input"
                    >
                      <option value="">Select ..</option>
                      {seriesList.map((asset) => (
                        <option key={asset.AssetId} value={JSON.stringify(asset)}>
                          {asset.seriesName}
                        </option>
                      ))}
                      <option value="new">Create New</option>
                    </select>
                  </label>
                </div>
                {selectedSeries === 'new' && (
                  <div>
                    <div className="mb-4">
                      <label htmlFor="series" className="soc-input-label focunLevel">
                        <span className="focusSpan soc-input-label-span">Series Name</span>
                        <input
                          disabled={isPosting || selectedSeries !== 'new' || isEditable}
                          id="seriesName"
                          type="text"
                          value={seriesName}
                          onChange={handleSeriesNameChange}
                          className="soc-input"
                        />
                      </label>
                    </div>
                    <div className="mb-4">
                      <label htmlFor="seriesDescription" className="soc-input-label focunLevel">
                        <span className="focusSpan soc-input-label-span">Series Description</span>
                        <textarea
                          disabled={isPosting || selectedSeries !== 'new'}
                          id="seriesDescription"
                          rows={2}
                          value={seriesDescription}
                          onChange={handleSeriesDescriptionChange}
                          className="soc-input"
                        />
                      </label>
                    </div>
                  </div>
                )}
                <div className="mb-4">
                  <label htmlFor="episodNo" className="soc-input-label focunLevel">
                    <span className="focusSpan soc-input-label-span">Episode Number</span>
                    <input
                      disabled={isPosting}
                      id="episodNo"
                      type="text"
                      value={episodNo}
                      onChange={handleEpisodeNumberChange}
                      className="soc-input"
                    />
                  </label>
                </div>
                {!isEditable && selectedSeries === 'new' && (
                  <div className="border-t mt-5">
                    <p className="mb-3 text-[#47464F] font-semibold text-medium mt-3">
                      Series Media
                    </p>
                    <div className="mb-4">
                      <div className="flex justify-between">
                        <label
                          htmlFor="imageUpload"
                          className="block mb-1 font-normal text-gray-800 text-sm"
                        >
                          Upload Series Cover
                        </label>
                      </div>

                      <FilePond
                        disabled={isPosting}
                        className="newAssetPond"
                        maxFiles={1}
                        onremovefile={seriesCoverOnRemove}
                        onupdatefiles={onChangeSeriesCoverFile}
                        allowImagePreview
                        acceptedFileTypes={['image/png', 'image/jpeg']}
                      />
                    </div>
                  </div>
                )}
              </div>
            )} */}
          <div className="mb-4">
            <label htmlFor="title" className="soc-input-label focunLevel">
              <span className="focusSpan soc-input-label-span">Title</span>
              <input
                disabled={isPosting}
                id="title"
                type="text"
                value={title}
                onChange={handleTitleChange}
                className="soc-input"
              />
            </label>
          </div>

          <div className="mb-4">
            <label htmlFor="details" className="soc-input-label focunLevel">
              <span className="focusSpan soc-input-label-span">Description</span>
              <textarea
                disabled={isPosting}
                rows={2}
                id="details"
                value={details}
                onChange={handleDetailsChange}
                className="soc-input"
              />
            </label>
          </div>

          <div className="mb-4">
            <label htmlFor="premium" className="soc-input-label focunLevel">
              <span className="focusSpan soc-input-label-span">Premium</span>

              <select
                disabled={isPosting}
                id="premium"
                value={isPremium}
                onChange={handleIsPremiumChange}
                className="soc-input"
              >
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </label>
          </div>

          <div className="mb-4">
            <label htmlFor="type" className="soc-input-label focunLevel">
              <span className="focusSpan soc-input-label-span">Type</span>

              <select
                disabled={isPosting}
                id="type"
                value={type}
                onChange={handleTypeChange}
                className="soc-input"
              >
                <option>Select...</option>
                <option value="audio">Audio</option>
              </select>
            </label>
          </div>

          <div className="mb-4">
            <label htmlFor="appleStoreProductId" className="soc-input-label focunLevel">
              <span className="focusSpan soc-input-label-span">Apple Store Product Id</span>
              <input
                disabled={isPosting}
                id="appleStoreProductId"
                type="text"
                value={appleStoreId}
                onChange={handleAppleStoreId}
                className="soc-input"
              />
            </label>
          </div>

          <div className="mb-4">
            <label htmlFor="playStoreProductId" className="soc-input-label focunLevel">
              <span className="focusSpan soc-input-label-span">Play Store Product Id</span>
              <input
                disabled={isPosting}
                id="playStoreProductId"
                type="text"
                value={playStoreId}
                onChange={handlePlayStoreId}
                className="soc-input"
              />
            </label>
          </div>

          {assetDuration !== '00:00' && (
            <div className="mb-4">
              <label htmlFor="assetDuration" className="soc-input-label focunLevel">
                <span className="focusSpan soc-input-label-span">Duration</span>
                <input
                  readOnly
                  disabled={isPosting}
                  id="assetDuration"
                  type="text"
                  placeholder="00:00"
                  value={assetDuration}
                  className="soc-input"
                />
              </label>
            </div>
          )}

          {!isEditable && (
            <div className="border-t mt-5">
              <p className="mb-3 text-[#47464F] font-semibold text-medium mt-3">Media</p>
              <div className="mb-4">
                <div className="flex justify-between">
                  <label
                    htmlFor="imageUpload"
                    className="block mb-1 font-normal text-gray-800 text-sm"
                  >
                    Upload Asset
                  </label>
                  {/* {!!imgSrc && (
                  <Button className="w-[50px] h-[30px]" onClick={() => setIsCropping(true)}>
                    Crop
                  </Button>
                )} */}
                </div>

                <FilePond
                  ref={editContentPondRef}
                  disabled={isPosting}
                  className="newAssetPond"
                  maxFiles={1}
                  onremovefile={contentOnRemove}
                  onupdatefiles={setContent}
                  acceptedFileTypes={[
                    'audio/mpeg',
                    'video/mp4',
                    'audio/aac',
                    'audio/mp4',
                    'audio/x-m4a',
                    'audio/wav',
                  ]}
                />

                {/* {!!completedCrop && (
                <div className="w-[500px] object-contain">
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: '1px solid black',
                      objectFit: 'contain',
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                </div>
              )} */}

                {/* <Popup open={isCropping} closeOnDocumentClick onClose={disableCrop}>
                <ReactCrop
                  crop={cropImage}
                  onChange={(c) => setCropImage(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                >
                  <img
                    ref={imgRef}
                    className="image-crop-preview object-contain"
                    alt="edit"
                    src={imgSrc}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
                <div className="flex justify-end">
                  <Button onClick={disableCrop}>Crop</Button>
                </div>
              </Popup> */}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="audioUpload"
                  className="block mb-1 font-normal text-gray-800 text-sm"
                >
                  Upload Cover Art
                </label>
                <FilePond
                  disabled={isPosting}
                  className="newAssetPond"
                  maxFiles={1}
                  onremovefile={imageOnRemove}
                  onupdatefiles={onChangeImageFile}
                  allowImagePreview
                  acceptedFileTypes={['image/png', 'image/jpeg']}
                />
              </div>
            </div>
          )}

          {!!msgText && (
            // <Alert
            //   className={`${
            //     isUploadSuccess ? 'bg-green-200' : 'bg-red-200'
            //   } mb-2 text-black font-bold`}
            // >
            //   {msgText}
            // </Alert>
            <ErrorAlert message={msgText} />
          )}

          <div className="flex">
            <button
              disabled={isPosting}
              type="submit"
              className="soc-button flex items-center justify-center px-4 w-full"
            >
              <p className="mr-2">{isEditable ? 'Update' : 'Add New'} Asset </p>{' '}
              {isPosting && <Spinner className="w-4 h-4" />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
