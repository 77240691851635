import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AssetsList from '../Components/Assets/AssetsList';

import AssetsListOrderable from '../Components/Assets/AssetListOrderable';
import UpdateSeries from '../Components/Upload/UpdateSeries';
import { UploadAsset } from '../Components/Upload/UploadAsset';
import { rmoveAssetDetails, setAssetDetails } from '../features/assets/assetsSlice';
import { removeSeriesDetails } from '../features/assets/seriesSlice';

const Asset = () => {
  const location = useLocation();
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };
  const categoryId = getQueryParam('categoryId');
  const [newAsset, setNewAsset] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [closeDetails, setCloseDetails] = useState(false);
  const { assetsDetails } = useSelector((state) => state.assets);
  const { seriesDetails, isEpisodeShow } = useSelector((state) => state.series);
  const [isSeriesUpdate, setSeriesUpdate] = useState(false);
  const dispatch = useDispatch();

  const handelClickNewAsset = () => {
    dispatch(rmoveAssetDetails());
    setIsEditable(false);
    setNewAsset(true);
    setSeriesUpdate(false);
  };
  useEffect(() => {
    if (Object.keys(assetsDetails).length > 0) {
      dispatch(removeSeriesDetails());
      setIsEditable(true);
      setNewAsset(true);
      setSeriesUpdate(false);
    }
  }, [assetsDetails, dispatch]);
  useEffect(() => {
    if (Object.keys(seriesDetails).length > 0) {
      dispatch(rmoveAssetDetails());
      setIsEditable(false);
      setNewAsset(false);
      setSeriesUpdate(true);
    }
  }, [dispatch, seriesDetails]);

  const handelCancel = () => {
    setIsEditable(false);
    setNewAsset(false);
    dispatch(setAssetDetails({}));
    dispatch(removeSeriesDetails());
    setSeriesUpdate(false);
  };

  useEffect(() => {
    setCloseDetails(false);
    if (closeDetails) {
      setIsEditable(false);
      setNewAsset(false);
      setSeriesUpdate(false);
    }
  }, [closeDetails, dispatch]);

  useEffect(() => {
    if (categoryId) {
      setIsEditable(false);
      setNewAsset(false);
      setSeriesUpdate(false);
    }
  }, [categoryId]);
  return (
    <section className="container p-3 mx-auto">
      <div className="grid grid-cols- xl:grid-cols-3 gap-7">
        <div className="col-span-2 bg-[#F7FAF9] p-8 rounded-xl overflow-hidden relative">
          <div className="flex justify-between items-center pb-3">
            <h2 className="text-3xl font-normal text-soc-light-on-surface-variant">Assets</h2>

            <button
              onClick={handelClickNewAsset}
              type="button"
              className="soc-button w-auto flex items-center justify-center px-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={18}
                height={19}
                viewBox="0 0 18 19"
                fill="none"
              >
                <path
                  d="M15 9.78271H9.75V15.0327H8.25V9.78271H3V8.28271H8.25V3.03271H9.75V8.28271H15V9.78271Z"
                  fill="white"
                />
              </svg>
              <span className="mx-2">Add new Asset</span>
            </button>
          </div>

          {/* <AssetsList /> */}
          {!categoryId && <AssetsList setCloseDetails={setCloseDetails} />}
          {categoryId && <AssetsListOrderable setCloseDetails={setCloseDetails} />}
        </div>

        {newAsset && !isSeriesUpdate && (
          <div className="col-span-2 xl:col-span-1">
            <div className="bg-gray-100 bg-opacity-75 rounded-lg overflow-hidden relative">
              {/* <AssetsDetails /> */}
              <UploadAsset
                isEditable={isEditable}
                handelCancel={handelCancel}
                setCloseDetails={setCloseDetails}
                tagId={categoryId}
              />
            </div>
          </div>
        )}
        {isSeriesUpdate && (
          <div className="col-span-2 xl:col-span-1">
            <div className="bg-gray-100 bg-opacity-75 rounded-lg overflow-hidden relative">
              <UpdateSeries
                handelCancel={handelCancel}
                setCloseDetails={setCloseDetails}
                isEpisodeShow={isEpisodeShow}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Asset;
