import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

const PublicLayout = () => {
  const navigate = useNavigate();
  const authData = useSelector((state) => state.authentication);
  const isUserLoggedIn = !!authData?.accessToken;

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate('/assets');
    }
  }, [isUserLoggedIn, navigate]);

  return <Outlet />;
};

export default PublicLayout;
